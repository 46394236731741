import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Box,
  Fieldset,
  FormGroup,
  Label,
  Legend,
  Span,
  useFormGroupContext,
} from '@allied-solutions/affinity'

import { Hint } from './Hint'

const RadioGroup = styled(Box)`
  display: flex;
  gap: ${props => props.theme.space[8]};
`
const CustomRadio = styled(FormGroup.Radio)`
  display: flex;
  flex: 1;

  /* padding: ${props => props.theme.space[2]} ${props =>
    props.theme.space[4]}; */

  input[type='radio']:checked + ${Label} {
    border: 1px solid ${props => props.theme.colors.blue800};
    background-color: ${props => props.theme.colors.lightBlue200};
  }

  input[type='radio']:not(:checked) + ${Label} {
    border: 1px solid ${props => props.theme.colors.gray200};
  }

  &:focus-within {
    ${Label} {
      outline: 4px solid #85cffe;
    }
  }

  ${Label} {
    color: black !important;
    background-color: ${props => props.theme.colors.gray20};
    border-radius: ${props => props.theme.space[2]};
    width: 100%;
    display: flex;
    padding: ${props => props.theme.space[2]} ${props => props.theme.space[4]};
    > ${Span}:last-child {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  ${Label} {
    & > span:first-child {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 1.75rem;
      border: 1px solid ${props => props.theme.colors.gray200};

      svg {
        /* styles for the SVG element inside the span */
        visibility: hidden; /* or display: none; to completely hide the SVG */
      }
    }
    & > span:last-child {
      color: rgb(0, 23, 62) !important;
      font-size: 16px;
    }

    span {
      &[class*='StyledRadioChecked'] {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 1.75rem;
        border: 1px solid ${props => props.theme.colors.blue800};
        background-color: white;

        svg {
          /* styles for the SVG element inside the span */
          visibility: hidden; /* or display: none; to completely hide the SVG */
        }

        &::before {
          content: '';
          display: block;
          box-sizing: border-box;
          position: absolute;
          left: 5px;
          top: 1px;
          width: 6px;
          height: 10px;
          border-width: 0 2px 2px 0;
          border-style: solid;
          transform-origin: bottom left;
          transform: rotate(45deg);
        }
      }
    }

    &:hover {
      & > span:first-child::before {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 10px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        transform-origin: bottom left;
        transform: rotate(45deg);
      }
    }
  }
`

const YesNo = ({ label, name, tooltipContent, checked, ...rest }) => {
  const { id } = useFormGroupContext()

  return (
    <Fieldset>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={['space-between', null, null]}
        mb={3}
      >
        <Legend mr={4}>{label}</Legend>

        <Hint id={`Tooltip--${id}`} content={tooltipContent} />
      </Box>
      <RadioGroup>
        <CustomRadio id={`${id}__yes`} label="Yes" value="true" />
        <CustomRadio id={`${id}__no`} label="No" value="false" />
      </RadioGroup>
    </Fieldset>
  )
}

YesNo.propTypes = {
  label: PropTypes.string.isRequired,
}

export { YesNo }
