import styled from 'styled-components'

import { Ul } from '@allied-solutions/affinity'

const StyledUl = styled(Ul)`
  margin-top: ${props => props.theme.space[6]};
  margin-left: -${props => props.theme.space[4]};
  margin-right: -${props => props.theme.space[4]};
  flex-wrap: wrap;

  ${props => props.theme.mediaQueries.sm} {
    display: flex;
    margin-bottom: -${props => props.theme.space[6]};
  }

  li {
    width: 100%;
    flex: 0 0 33.333333%;

    padding-left: ${props => props.theme.space[4]};
    padding-right: ${props => props.theme.space[4]};
    margin-bottom: ${props => props.theme.space[6]};

    ${props => props.theme.mediaQueries.sm} {
      max-width: 33.333333%;
    }
  }
`

export { StyledUl }
