import React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../hooks'

const Layout = ({ children }) => {
  const { title } = useSiteMetadata()

  return (
    <>
      <Helmet
        defaultTitle={title}
        titleTemplate={`%s | ${title}`}
        htmlAttributes={{ lang: 'en-US' }}
        role="main"
      />
      {children}
    </>
  )
}

export { Layout }
