import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { use100vh } from 'react-div-100vh'

import {
  Box,
  Button,
  Icon,
  P,
  Ul,
  Span,
  ButtonUnstyled,
} from '@allied-solutions/affinity'
import {
  Menu,
  Close1,
  ChevronDown,
} from '@allied-solutions/affinity-react-icons/dist/16px'

import { theme } from '../theme.js'
import { useWindowWidth } from '../hooks'
import { Logo } from './Logo'
import { LinkAnchor } from './LinkAnchor.js'

const NavBox = styled(Box)``
const StyledText = styled(P)``
const StyledSpan = styled(Span)`
  line-height: 26px;
  text-align: right;
  color: rgb(0, 23, 62);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

console.log('theme', theme)

const StyledNav = styled.nav`
  transition: all 0.5s ease-in;
  ${props =>
    props.moving
      ? css`
          ${StyledText} {
            color: ${props.theme.colors.gray1000};
          }

          ${StyledSpan} {
            color: rgb(0, 23, 62);
          }

          ${NavBox} {
            padding-top: 1.5rem;
            /* padding-top: ${props.theme.space[6]}; */
            padding-bottom: ${props.theme.space[6]};
            background-color: ${props.theme.colors.white};
            border-bottom: 1px solid ${props.theme.colors.muted};
            position: fixed;
            height: 85px;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-name: showStickyHeader;

            ${props => props.theme.mediaQueries.md} {
              height: 100px;
            }

            ${props.theme.mediaQueries.sm} {
              height: 100px;
              padding-bottom: 1.55rem;
              padding-top: 1.61rem;
              /* padding-top: ${props.theme.space[5]};
              padding-bottom: ${props.theme.space[4]}; */
            }
          }
        `
      : css`
          ${NavBox} {
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-name: unstickyHeader;
          }
        `}

  @keyframes showStickyHeader {
    0% {
      opacity: 0;
      transform: translateY(-5%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      /* height: $header-height-sticky; */
    }
  }
  @keyframes unstickyHeader {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const StyledNavNoAnimation = styled.nav`
  ${props => css`
    padding-top: ${props.theme.space[6]};
    padding-bottom: ${props.theme.space[6]};
    background-color: ${props.theme.colors.white};
    background: white;
    border-bottom: 1px solid ${props.theme.colors.muted};
    position: fixed;
    height: 100px;
    width: inherit;
  `}
`

const StyledBox = styled(Box)`
  position: static;
  border: 2px solid;
  border-color: ${props => props.theme.colors.muted};
  border-radius: 12px;
  box-sizing: border-box;
  padding: ${props => props.theme.space[6]};
  margin-top: ${props => props.theme.space[7]};
  cursor: pointer;
`

// const StyledButton = styled(Button)`
//   display: block;
//   width: 100%;
// `
const mediumBreakpoint = Number(theme[1].replace('px', ''))

const Nav = ({ underlineHome, underlineSupport, liColor }) => {
  const windowWidth = useWindowWidth()
  const [scrollPosition, setScrollPosition] = useState(undefined)
  const [needMenu, setNeedMenu] = useState(false)
  const [movingNav, setMovingNav] = useState(false)

  const menuHeight = use100vh()

  // on mount, add scroll event listeners
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // when scroll position changes, set movingNav appropriately
  useEffect(() => {
    setMovingNav(scrollPosition >= 60)
  }, [scrollPosition])

  useEffect(() => {
    document.body.style.overflow = needMenu ? 'hidden' : ''
  }, [needMenu])

  useEffect(() => {
    if (windowWidth > mediumBreakpoint && needMenu) {
      setNeedMenu(false)
    }
  }, [windowWidth, needMenu])

  return (
    <>
      {windowWidth >= 1024 && windowWidth <= 1380 ? (
        <StyledNavNoAnimation aria-label="Navigation">
          <NavBox
            id="homeSupportNav"
            display="flex"
            px={[8, 8, 8, 15]}
            py={[7, 7, 7, 8]}
            position="fixed"
            top="0"
            right="0"
            left="0"
            zIndex="100"
            css={`
              @media print {
                display: block;
              }
            `}
          >
            <Logo
              id="Global__Header__Logo__Nav"
              css={`
                @media print {
                  img {
                    display: block;
                    margin: 0 auto;
                  }
                }
              `}
            />
            <Box ml="auto">
              {windowWidth <= mediumBreakpoint ? (
                needMenu ? (
                  <>
                    <Box
                      px={['6', '8']}
                      pt={5}
                      pb="20"
                      top="0"
                      left="0"
                      bg="white"
                      position="fixed"
                      height={menuHeight}
                      overflow="auto"
                      zIndex="7"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Logo />
                        <ButtonUnstyled
                          onClick={() => setNeedMenu(!needMenu)}
                          id="homeSupportMenuToggle"
                          aria-label="close menu"
                        >
                          <Box
                            width="8"
                            height="8"
                            borderRadius="8"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gradient="blue"
                          >
                            <Icon src={Close1} color="white" />
                          </Box>
                        </ButtonUnstyled>
                      </Box>
                      <P
                        mb={0}
                        typeStyle="displayExtraLarge"
                        textAlign="center"
                        display="block"
                        mt="19"
                        fontWeight="900"
                      >
                        We are here to&nbsp;help
                      </P>
                      <P
                        typeStyle="displaySmall"
                        textAlign="center"
                        mt="7"
                        display="block"
                        mb="15"
                      >
                        We work with financial institutions to verify that you
                        have the right level of insurance coverage. It should
                        only take a few minutes.
                      </P>
                      <LinkAnchor
                        to="/"
                        id="Global__Header__Link--home"
                        onClick={() => setNeedMenu(!needMenu)}
                      >
                        <StyledBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <P
                            mb={0}
                            typeStyle="displaySmall"
                            textAlign="left"
                            fontWeight="900"
                            style={{ color: 'rgb(0, 23, 62)' }}
                          >
                            Home
                          </P>
                          <Icon
                            src={ChevronDown}
                            css={`
                              transform: rotate(-90deg);
                            `}
                          />
                        </StyledBox>
                      </LinkAnchor>
                      <LinkAnchor
                        to="/support/"
                        id="Global__Header__Link--support"
                        onClick={() => setNeedMenu(!needMenu)}
                      >
                        <StyledBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb="12"
                        >
                          <P
                            mb={0}
                            typeStyle="displaySmall"
                            textAlign="left"
                            fontWeight="900"
                            style={{ color: 'rgb(0, 23, 62)' }}
                          >
                            Support
                          </P>
                          <Icon
                            src={ChevronDown}
                            css={`
                              transform: rotate(-90deg);
                            `}
                          />
                        </StyledBox>
                      </LinkAnchor>
                      <Button
                        id="Global__Header__Button--enterReferenceId"
                        rounded
                        forwardedAs={Link}
                        boxShadow="none"
                        to="/enter-reference-id/"
                        display="block"
                        minWidth="100%"
                        size="lg"
                      >
                        <Span fontSize="16px">Enter Reference ID</Span>
                      </Button>
                    </Box>
                  </>
                ) : (
                  <ButtonUnstyled
                    id="homeSupportMenuToggle"
                    onClick={() => setNeedMenu(!needMenu)}
                  >
                    Menu <Icon ml="2" src={Menu} />
                  </ButtonUnstyled>
                )
              ) : (
                <Ul
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  css={`
                    li {
                      margin-left: ${props => props.theme.space[10]};
                    }
                  `}
                >
                  <li>
                    <LinkAnchor id="Global__Header__Link--home" to="/">
                      <StyledSpan
                        className="navSupportLi"
                        typeStyle="bodyMedium"
                        textAlign="right"
                        color={liColor}
                        fontWeight="bold"
                        css={
                          underlineHome
                            ? `
                      @media only screen and (min-width: 1380px) {
                        color: white;
                      }
                      `
                            : null
                        }
                      >
                        Home
                      </StyledSpan>
                    </LinkAnchor>
                    {underlineHome && (
                      <Box gradient="blue" width="13" height="1" />
                    )}
                  </li>
                  <li>
                    <LinkAnchor
                      id="Global__Header__Link--support"
                      to="/support/"
                    >
                      <StyledSpan
                        className="navSupportLi"
                        typeStyle="bodyMedium"
                        textAlign="right"
                        color={liColor}
                        fontWeight="bold"
                        css={
                          underlineHome
                            ? `
                      @media only screen and (min-width: 1380px) {
                        color: white;
                      }
                    `
                            : null
                        }
                      >
                        Support
                      </StyledSpan>
                    </LinkAnchor>
                    {underlineSupport && (
                      <Box gradient="blue" width="17" height="1" />
                    )}
                  </li>
                  <li>
                    <Button
                      id="Global__Header__Button--enterReferenceId"
                      rounded
                      forwardedAs={Link}
                      boxShadow="none"
                      to="/enter-reference-id/"
                    >
                      <Span fontSize="16px">Enter Reference ID</Span>
                    </Button>
                  </li>
                </Ul>
              )}
            </Box>
          </NavBox>
        </StyledNavNoAnimation>
      ) : (
        <StyledNav moving={movingNav} aria-label="Navigation">
          <NavBox
            height={['85px', '100px']}
            id="homeSupportNav"
            display="flex"
            px={[8, 8, 8, 15]}
            py={[7, 7, 7, 8]}
            pt={['1.7rem']}
            position="fixed"
            top="0"
            right="0"
            left="0"
            zIndex="100"
            css={`
              align-items: center;
              @media print {
                display: block;
              }
            `}
          >
            <Logo
              id="Global__Header__Logo__Nav"
              css={`
                @media print {
                  img {
                    display: block;
                    margin: 0 auto;
                  }
                }
              `}
            />

            <Box ml="auto">
              {/*medium and large display & transparent */}

              {windowWidth <= mediumBreakpoint ? (
                needMenu ? (
                  <>
                    <Box
                      px={['6', '8']}
                      pt={5}
                      pb="20"
                      top="0"
                      left="0"
                      bg="white"
                      position="fixed"
                      height={menuHeight}
                      overflow="auto"
                      zIndex="7"
                      className="mobileCollapseMenu"
                    >
                      <Box className="mobileContent">
                        <Box
                          className="mobileHeader"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          css={`
                            padding-top: 0.95rem;
                            padding-left: 0.5rem;

                            ${props => props.theme.mediaQueries.sm} {
                              padding-top: 1.32rem;
                              padding-left: 0rem;
                            }
                          `}
                        >
                          <Logo />
                          <ButtonUnstyled
                            onClick={() => setNeedMenu(!needMenu)}
                            id="homeSupportMenuToggle"
                            aria-label="close menu"
                            position="fixed"
                            right="2rem"
                          >
                            <Box
                              width="8"
                              height="8"
                              borderRadius="8"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              gradient="blue"
                            >
                              <Icon src={Close1} color="white" />
                            </Box>
                          </ButtonUnstyled>
                        </Box>
                        <P
                          mb={0}
                          typeStyle="h2"
                          textAlign="center"
                          display="block"
                          mt="19"
                          fontWeight="900"
                        >
                          We are here to&nbsp;help
                        </P>
                        <P
                          textAlign="center"
                          mt="7"
                          display="block"
                          mb="15"
                          fontSize="22px"
                          fontWeight={400}
                          lineHeight="36px"
                        >
                          We work with financial institutions to verify that you
                          have the right level of insurance coverage. It should
                          only take a few minutes.
                        </P>
                        <LinkAnchor
                          to="/"
                          id="Global__Header__Link--home"
                          onClick={() => setNeedMenu(!needMenu)}
                        >
                          <StyledBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <P
                              mb={0}
                              fontSize="22px"
                              textAlign="left"
                              fontWeight="900"
                              style={{ color: 'rgb(0, 23, 62)' }}
                            >
                              Home
                            </P>
                            <Icon
                              src={ChevronDown}
                              css={`
                                transform: rotate(-90deg);
                              `}
                            />
                          </StyledBox>
                        </LinkAnchor>
                        <LinkAnchor
                          to="/support/"
                          id="Global__Header__Link--support"
                          onClick={() => setNeedMenu(!needMenu)}
                        >
                          <StyledBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb="12"
                          >
                            <P
                              mb={0}
                              fontSize="22px"
                              textAlign="left"
                              fontWeight="900"
                              style={{ color: 'rgb(0, 23, 62)' }}
                            >
                              Support
                            </P>
                            <Icon
                              src={ChevronDown}
                              css={`
                                transform: rotate(-90deg);
                              `}
                            />
                          </StyledBox>
                        </LinkAnchor>
                        <Button
                          id="Global__Header__Button--enterReferenceId"
                          rounded
                          forwardedAs={Link}
                          boxShadow="none"
                          to="/enter-reference-id/"
                          display="block"
                          minWidth="100%"
                          size="lg"
                        >
                          Enter Reference ID
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <ButtonUnstyled
                    typeStyle="bodyMedium"
                    id="homeSupportMenuToggle"
                    onClick={() => setNeedMenu(!needMenu)}
                  >
                    Menu <Icon ml="2" src={Menu} />
                  </ButtonUnstyled>
                )
              ) : (
                <Ul
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  css={`
                    li {
                      margin-left: ${props => props.theme.space[10]};
                    }
                  `}
                >
                  <li>
                    <LinkAnchor id="Global__Header__Link--home" to="/">
                      <StyledSpan
                        className="navSupportLi"
                        typeStyle="bodyMedium"
                        textAlign="right"
                        color={liColor}
                        fontWeight="bold"
                        css={
                          underlineHome
                            ? `
                      @media only screen and (min-width: 1380px) {
                        color: white;
                      }
                      `
                            : null
                        }
                      >
                        Home
                      </StyledSpan>
                    </LinkAnchor>
                    {underlineHome && (
                      <Box gradient="blue" width="13" height="1" />
                    )}
                  </li>
                  <li>
                    <LinkAnchor
                      id="Global__Header__Link--support"
                      to="/support/"
                    >
                      <StyledSpan
                        className="navSupportLi"
                        typeStyle="bodyMedium"
                        textAlign="right"
                        color={liColor}
                        fontWeight="bold"
                        css={
                          underlineHome
                            ? `
                      @media only screen and (min-width: 1380px) {
                        color: white;
                      }
                    `
                            : null
                        }
                      >
                        Support
                      </StyledSpan>
                    </LinkAnchor>
                    {underlineSupport && (
                      <Box gradient="blue" width="17" height="1" />
                    )}
                  </li>
                  <li>
                    <Button
                      id="Global__Header__Button--enterReferenceId"
                      rounded
                      forwardedAs={Link}
                      boxShadow="none"
                      to="/enter-reference-id/"
                    >
                      <Span fontSize="16px">Enter Reference ID</Span>
                    </Button>
                  </li>
                </Ul>
              )}
            </Box>
          </NavBox>
        </StyledNav>
      )}
    </>
  )
}

export { Nav }
