import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { fluidRange } from 'polished'

import { Box, P } from '@allied-solutions/affinity'

import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { Logo } from '../Logo'
import { Help } from '../Help'

import { BackButton } from './BackButton'
import { InfoBox } from './InfoBox'
import { NextButton } from './NextButton'

const AppLayoutGlobalStyle = createGlobalStyle`
  body {
    ${props => props.theme.mediaQueries.lg} {
      background-color: ${props => props.theme.colors.lightBlue120} !important;
    }
  }
`

const AppLayout = ({ leftSide, rightSide, footerButtons }) => {
  const { copyrightText } = useSiteMetadata()

  return (
    <>
      <AppLayoutGlobalStyle />
      <Box
        borderTopRightRadius={[null, null, null, 8]}
        borderBottomRightRadius={[null, null, null, 8]}
        boxShadow={[null, null, null, 'xl']}
        width={[null, null, null, '35%']}
        minWidth={[null, null, null, '385px']}
        maxWidth={[null, null, null, '66ch']}
        height={[null, null, null, '100vh']}
        position={[null, null, null, 'fixed']}
        top={[null, null, null, 0]}
        bg={[null, null, null, 'white']}
        overflow={[null, null, null, 'auto']}
        css={`
          @media print {
            display: none;
          }
        `}
      >
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight={[null, null, null, '100vh']}
          >
            <Box
              height={['85px', '100px', null, null]}
              py={['5', null, '5', '11']}
              px={['6', '8', '8', '15']}
              padding={['2.2rem 2rem', '2.53rem 2rem', '2.3rem 2rem', null]}
              boxShadow={['xl', 'xl', 'xl', 'none']}
              position={['fixed', 'fixed', 'fixed', 'static']}
              zIndex="5"
              bg={['white', 'white', 'white', 'transparent']}
              width="100%"
            >
              <Logo id={'MyInsuranceInfo__Header__Logo'} />
            </Box>

            <Box
              px={['8', '8', '8', '15']}
              pt={['10rem', '10rem', '11rem', 4]}
              mb={[null, null, null, '15']}
            >
              {leftSide}
            </Box>

            <Box
              px={['8', '8', '8', '15']}
              pt={6}
              pb={7}
              borderTop="1px solid"
              borderColor="gray90"
              position={['fixed', 'fixed', 'fixed', 'static']}
              zIndex="6"
              bottom="0"
              right="0"
              width="100%"
              bg={['white', 'white', 'white', 'transparent']}
              boxShadow={['xl', 'xl', 'xl', 'none']}
            >
              {footerButtons}
            </Box>
          </Box>
        </Box>
      </Box>
      <Help />

      <Box
        px={8}
        py={5}
        css={`
          display: none;

          @media print {
            display: block;
          }
        `}
      >
        <Logo
          id="Global__Header__Logo__Layout"
          css={`
            img {
              display: block;
              margin: 0 auto;
            }
          `}
        />
      </Box>

      <Box
        width={[null, null, null, '65%']}
        maxWidth={[null, null, null, 'calc(100% - 385px)', '100%']}
        minWidth={[null, null, null, null, 'calc(100% - 66ch)']}
        ml="auto"
        px={['0', '0', '0', '15']}
        pb={[36, 36, 36, 0]}
        display="flex"
        flexDirection="column"
      >
        <Box
          minHeight={[null, null, null, '100vh']}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          textAlign="right"
        >
          <Box
            width="100%"
            maxWidth="132ch"
            mx="auto"
            my="auto"
            pt={[null, null, null, 32]}
            css={`
              ${props =>
                fluidRange(
                  [
                    {
                      prop: 'padding-left',
                      fromSize: '0px',
                      toSize: '84px',
                    },
                    {
                      prop: 'padding-right',
                      fromSize: '0px',
                      toSize: '84px',
                    },
                  ],
                  '1155px',
                  '1500px'
                )}
            `}
          >
            {rightSide}
          </Box>

          <P
            mb={10}
            mt={20}
            typeStyle="caption"
            // typeStyle={[
            //   'captionCenterDisabled',
            //   'captionCenterDisabled',
            //   'captionCenterDisabled',
            //   'captionRightDisabled',
            // ]}
            display={['none', null, null, 'block']}
            css={`
              @media print {
                text-align: center;
              }
            `}
          >
            {copyrightText}
          </P>
        </Box>
      </Box>
    </>
  )
}

export { AppLayout, BackButton, InfoBox, NextButton }
