import React from 'react'

import { Box, H4 } from '@allied-solutions/affinity'

const Section = ({ children, heading, fullWidth, ...rest }) => (
  <Box
    py={15}
    px={fullWidth && [null, null, null, 17]}
    mx={fullWidth && [null, null, null, -17]}
    position="relative"
    css={`
      & + & {
        border-top: 1px solid ${props => props.theme.colors.muted};
      }

      &:last-child {
        padding-bottom: 0;
      }
    `}
    {...rest}
  >
    {heading && (
      <H4 typeStyle="heading" textAlign="left" mb={-4}>
        {heading}
      </H4>
    )}
    {children}
  </Box>
)

export { Section }
