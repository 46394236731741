import React, { useContext } from 'react'
import styled from 'styled-components'

import {
  Box,
  ButtonUnstyled,
  Icon,
  P,
  Ul,
  mixins,
} from '@allied-solutions/affinity'
import {
  Document2Text,
  Close1,
} from '@allied-solutions/affinity-react-icons/dist/16px'

import { fileUploaderContext } from './FileUploader'

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.space[2]};
`

const FilesList = ({ allowRemove = true, ...rest }) => {
  const { files, setFiles } = useContext(fileUploaderContext)

  const handleRemove = file => {
    setFiles(files => {
      const filesCopy = [...files]
      filesCopy.splice(filesCopy.indexOf(file), 1)
      return filesCopy
    })
  }

  return (
    <Ul {...rest}>
      {files.map((file, index) => (
        <StyledLi key={file.name + index}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={8}
            minWidth={8}
            bg="lightBlue400"
            borderRadius="2"
          >
            <Icon src={Document2Text} color="lightBlue1000" />
          </Box>
          <P
            mb={0}
            ml={3}
            typeStyle="labelMedium"
            textAlign="left"
            css={mixins.oneLineText}
          >
            {file.name}
          </P>
          {allowRemove && (
            <Box ml="auto" pl={4}>
              <ButtonUnstyled
                id={`SubmitInsurance__FileUploader__RemoveFile--${index + 1}`}
                display="flex"
                typeStyle="labelMedium"
                textAlign="right"
                color="gray600"
                onClick={() => handleRemove(file)}
              >
                Remove
                <Icon src={Close1} color="blue800" ml={1} />
              </ButtonUnstyled>
            </Box>
          )}
        </StyledLi>
      ))}
    </Ul>
  )
}

export { FilesList }
