import React, { Children } from 'react'
import styled from 'styled-components'

import { Box } from '@allied-solutions/affinity'

const StyledOl = styled.ol`
  margin: 0;
  padding: 0;

  li {
    display: flex;

    &:before {
      ${props => props.theme.typeStyles.displayMediumLeftPrimary};

      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: ${props => props.theme.fontWeights.black};
      color: ${props => props.theme.colors.info};
      width: ${props => props.theme.sizes[12]};
      height: ${props => props.theme.sizes[12]};
      border-radius: 50%;
      background-color: ${props => props.theme.colors.lightBlue200};
      margin-right: ${props => props.theme.space[6]};
    }

    + li {
      margin-top: ${props => props.theme.space[7]};
    }

    h3 {
      font-size: ${props => props.theme.fontSizes[5]};
      margin-top: 0;
      margin-bottom: ${props => props.theme.space[2]};
    }

    h4 {
      margin-top: 0;
      margin-bottom: ${props => props.theme.space[3]};
    }

    p {
      margin-bottom: 0;
    }
  }
`

const FancyList = ({ children }) => {
  return (
    <StyledOl>
      {Children.map(children, (child, index) => (
        <li
          css={`
            &:before {
              content: '${index + 1}';
              font-size: ${props => props.theme.fontSizes[5]};
            }
          `}
        >
          <Box flex="1" pt={2}>
            {child}
          </Box>
        </li>
      ))}
    </StyledOl>
  )
}

export { FancyList }
