import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { Box, Button, ButtonAnchor } from '@allied-solutions/affinity'

const StyledButton = styled(Button)`
  box-shadow: none;
`
const StyledButtonAnchor = styled(ButtonAnchor)`
  box-shadow: none;
`

const ButtonGroup = styled(Box).attrs(props => ({
  display: props.display ?? [null, null, 'flex'],
  alignItems: props.alignItems ?? 'center',
  justifyContent: props.justifyContent ?? 'center',
}))`
  ${props =>
    props.stretch &&
    css`
      ${StyledButton} {
        flex: 1;
        max-width: 100%;
      }
    `}
`

ButtonGroup.propTypes = {
  stretch: PropTypes.bool,
}

const ButtonGroupAll = Object.assign(ButtonGroup, {
  Button: StyledButton,
  ButtonAnchor: StyledButtonAnchor,
})

export { ButtonGroupAll as ButtonGroup }
