import { useState, useEffect } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
require('date-time-format-timezone')


const useDisabledButtonMessaging = () => {
  const [liveChatDisabled, setLiveChatDisabled] = useState(false)
  const [phoneDisabled, setPhoneDisabled] = useState(false)
  const [chatWillOpenTime, setChatWillOpenTime] = useState('')
  const [phoneWillOpenTime, setPhoneWillOpenTime] = useState('')

  const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

  const timesChatIsOpen = [
    {},
    {
      open: 8,
      close: 17,
    },
    {
      open: 8,
      close: 17,
    },
    { open: 8, close: 17 },
    {
      open: 8,
      close: 17,
    },
    {
      open: 8,
      close: 17,
    },
    {},
  ]

  const timesPhoneIsOpen = [
    {},
    {
      open: 8,
      close: 21,
    },
    {
      open: 8,
      close: 21,
    },
    { open: 8, close: 21 },
    {
      open: 8,
      close: 21,
    },
    {
      open: 8,
      close: 21,
    },
    {
      open: 9,
      close: 18,
    },
  ]

  useEffect(() => {
    const est = utcToZonedTime(new Date(), "America/New_York")
    
    const currentDay = est.getDay()
    const currentHour = est.getHours()

    if (
      timesChatIsOpen[currentDay].open &&
      currentHour >= timesChatIsOpen[currentDay].open &&
      currentHour < timesChatIsOpen[currentDay].close
    ) {
      setLiveChatDisabled(false)
    } else setLiveChatDisabled(true)

    if (
      timesPhoneIsOpen[currentDay].open &&
      currentHour >= timesPhoneIsOpen[currentDay].open &&
      currentHour < timesPhoneIsOpen[currentDay].close
    ) {
      setPhoneDisabled(false)
    } else setPhoneDisabled(true)

    if (
      timesChatIsOpen[currentDay].open &&
      currentHour < timesChatIsOpen[currentDay].open
    ) {
      setChatWillOpenTime(
        `today at ${timesChatIsOpen[currentDay].open}:00 AM EST`
      )
    } else if (
      timesChatIsOpen[currentDay].open &&
      currentHour >= timesChatIsOpen[currentDay].close &&
      daysOfWeek[currentDay] !== 'Fri' &&
      daysOfWeek[currentDay] !== 'Sat'
    ) {
      setChatWillOpenTime(
        `tomorrow at ${timesChatIsOpen[currentDay + 1].open}:00 AM EST`
      )
    } else if (
      (daysOfWeek[currentDay] === 'Fri' &&
        currentHour >= timesChatIsOpen[currentDay].close) ||
      daysOfWeek[currentDay] === 'Sat' ||
      daysOfWeek[currentDay] === 'Sun'
    ) {
      setChatWillOpenTime(`Mon. at ${timesChatIsOpen[1].open}:00 AM EST`)
    } else {
      setChatWillOpenTime(`at the time listed above`)
    }

    if (
      timesPhoneIsOpen[currentDay].open &&
      currentHour < timesPhoneIsOpen[currentDay].open
    ) {
      setPhoneWillOpenTime(
        `today at ${timesPhoneIsOpen[currentDay].open}:00 AM EST`
      )
    } else if (
      timesPhoneIsOpen[currentDay].open &&
      currentHour >= timesPhoneIsOpen[currentDay].close &&
      daysOfWeek[currentDay] !== 'Sat'
    ) {
      setPhoneWillOpenTime(
        `tomorrow at ${timesPhoneIsOpen[currentDay + 1].open}:00 AM EST`
      )
    } else if (
      (daysOfWeek[currentDay] === 'Sat' &&
        currentHour >= timesPhoneIsOpen[currentDay].close) ||
      daysOfWeek[currentDay] === 'Sun'
    ) {
      setPhoneWillOpenTime(`Mon. at ${timesPhoneIsOpen[1].open}:00 AM EST`)
    } else {
      setPhoneWillOpenTime(`at the time listed above`)
    }

    const estMilliseconds = est.getTime()

    // 2021 holiday - labor day
    if (estMilliseconds >= 1630792800000 && estMilliseconds < 1631016000000) {
      setPhoneDisabled(true)
      setPhoneWillOpenTime(`Tues. at ${timesPhoneIsOpen[2].open}:00 AM EST`)
    }

    if (estMilliseconds >= 1630702800000 && estMilliseconds < 1631016000000) {
      setLiveChatDisabled(true)
      setChatWillOpenTime(`Tues. at ${timesChatIsOpen[2].open}:00 AM EST`)
    }
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    liveChatDisabled,
    phoneDisabled,
    chatWillOpenTime,
    phoneWillOpenTime,
  }
}

export { useDisabledButtonMessaging }
