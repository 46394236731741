import React from 'react'

import { Button, LoadingSpinner, Span } from '@allied-solutions/affinity'

import { usePageData } from '../../hooks'

const NextButton = ({
  formikProps: { isValid, isSubmitting, errors, touched },
  children,
  ...rest
}) => {
  const { pageSlug } = usePageData()

  let activeError = false
  if (errors && touched) {
    Object.keys(errors).forEach(error => {
      return Object.keys(touched).forEach(touch => {
        if (error === touch) {
          activeError = true
        }
      })
    })
  }

  if (pageSlug === 'upload-documents' && !isValid) activeError = true

  return (
    <Button
      id="SubmitInsurance__Button--next"
      type="submit"
      size="md"
      // minWidth={30}
      ml={['5', '8']}
      width="fit-content"
      disabled={activeError || isSubmitting}
      rounded
      variant={activeError ? 'red' : 'primary'}
      {...rest}
    >
      <span width="fit-content">
        {(isSubmitting && (
          <>
            <Span>
              <Span
                css={`
                  color: transparent;
                `}
              >
                {children || `Next`}
              </Span>
              <LoadingSpinner
                padding="2px"
                css={`
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  margin-right: 5px;
                `}
              >
                Loading...
              </LoadingSpinner>
            </Span>
          </>
        )) ||
          children ||
          `Next`}
      </span>
    </Button>
  )
}

export { NextButton }
