import React, { createContext, useEffect, useState } from 'react'

const fileUploaderContext = createContext()
const { Provider } = fileUploaderContext

const FileUploader = ({ files: filesProp, onChange, children }) => {
  const [files, setFiles] = useState(filesProp)

  useEffect(() => {
    onChange && onChange(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  // useEffect(() => {
  //   setFiles(filesProp)
  // }, [filesProp])

  return <Provider value={{ files, setFiles }}>{children}</Provider>
}

export { FileUploader, fileUploaderContext }
