import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import {
  AffinityProvider,
  Box,
  Button,
  Container,
  Flex,
  Icon,
  Paragraph,
  P,
  Ul,
  H1,
  Span,
} from '@allied-solutions/affinity'
import { RightArrow } from '@allied-solutions/affinity-react-icons/dist/24px'

import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { dark } from '../../theme.js'
import { Logo } from '../Logo'

// eliminate console warning for 'fancy' variant
Button.propTypes = {
  ...Button.propTypes,
  variant: PropTypes.oneOf(Object.keys(dark.buttons)),
}

const NavHeader = ({ children }) => (
  <P
    typeStyle="bodySmall"
    textAlign="center"
    color="lightBlue800"
    fontWeight="bold"
    display="block"
    mb={4}
    css={`
      text-transform: uppercase;
    `}
  >
    {children}
  </P>
)

const SubMenu = ({ children }) => (
  <Ul
    css={`
      li + li {
        margin-top: ${props => props.theme.space[1]};
      }
    `}
  >
    {children}
  </Ul>
)

const SubMenuItem = ({ slug, children }) => (
  <li>
    <Link
      id={`Global__Footer__Link--${slug}`}
      to={`/${slug}/`}
      css={`
        text-decoration: none;
      `}
    >
      <P mb={0} color={'#FFFFFF'}>
        {children}
      </P>
    </Link>
  </li>
)

const BottomOfFooter = () => {
  const { alliedInfo, copyrightText } = useSiteMetadata()
  return (
    <>
      <Box pt={20} pb={[24, 24, 32]}>
        <nav>
          <Ul
            display={[null, null, 'flex']}
            css={`
              li {
                flex: 1;
              }

              > li + li {
                margin-top: ${props => props.theme.space[8]};
              }

              ${props => props.theme.mediaQueries.md} {
                > li + li {
                  margin-top: 0;
                }
              }
            `}
          >
            <li>
              <NavHeader>Learn More</NavHeader>
              <SubMenu>
                <SubMenuItem slug="support">Support</SubMenuItem>
              </SubMenu>
            </li>
            <li>
              <NavHeader>Trust and Security</NavHeader>
              <SubMenu>
                <SubMenuItem slug="terms-conditions">
                  Terms & Conditions
                </SubMenuItem>
                <SubMenuItem slug="privacy-policy">Privacy Policy</SubMenuItem>
              </SubMenu>
            </li>
            <li>
              <NavHeader>About Allied</NavHeader>
              <SubMenu>
                <li>
                  <a
                    id="Global__Footer__Link--allied-solutions"
                    href={alliedInfo.siteUrl}
                    target="_blank"
                    rel="noreferrer"
                    css={`
                      text-decoration: none;
                    `}
                  >
                    <P mb={0} color="#FFFFFF">
                      Allied Solutions
                    </P>
                  </a>
                </li>
                <li>
                  <a
                    id="Global__Footer__Link--our-partners"
                    href="https://www.alliedsolutions.net/approach/Partners"
                    target="_blank"
                    rel="noreferrer"
                    css={`
                      text-decoration: none;
                    `}
                  >
                    <P
                      mb={0}
                      display="block"
                      textAlign="center"
                      color="#FFFFFF"
                    >
                      Our Partners
                    </P>
                  </a>
                </li>
              </SubMenu>
            </li>
          </Ul>
        </nav>
      </Box>
      <Flex display={[null, null, 'flex']} alignItems="center">
        <Flex.Col
          order="2"
          pb={[10, 10, 0]}
          mb={[5, 5, 0]}
          borderBottom={[`1px solid`, `1px solid`, 0]}
          borderBottomColor={['blue', 'blue', 'transparent']}
          display="flex"
          justifyContent="center"
        >
          <Logo
            id={`Global__Footer__Logo`}
            white
            css={`
              img {
                display: block;
                margin: 0 auto;
              }
            `}
          />
        </Flex.Col>
        <Flex.Col order="1">
          <Paragraph
            typeStyle="bodySmall"
            color="lightBlue800"
            mb={0}
            textAlign={['center', 'center', 'left']}
          >
            {alliedInfo.address}
          </Paragraph>
        </Flex.Col>
        <Flex.Col order="3">
          <Paragraph
            typeStyle="bodySmall"
            color="lightBlue800"
            mb={0}
            textAlign={['center', 'center', 'right']}
          >
            {copyrightText}
          </Paragraph>
        </Flex.Col>
      </Flex>
    </>
  )
}

const Footer = ({ className }) => {
  return (
    <AffinityProvider theme={dark}>
      <Box
        bg="background"
        pt={26}
        pb={14}
        color="white"
        textAlign="center"
        className={className}
        as="footer"
      >
        <Container px={8}>
          <H1
            typeStyle="displaySmall"
            fontWeight="900"
            // lineHeight="40px"
            // className="h3"
            css={`
              margin-bottom: ${props => props.theme.space[7]};
              /* font-weight: ${props => props.theme.fontWeights.black};
              line-height: ${props => props.theme.lineHeights[9]};
              font-size: ${props => props.theme.fontSizes[9]};
              letter-spacing: ${props => props.theme.space[0]}; */
            `}
          >
            We’re here for you
          </H1>
          <Box maxWidth={[null, null, '45vw']} mx="auto">
            <P
              fontSize={'18px'}
              textAlign="center"
              color="white"
              mb={12}
              lineHeight={'30px'}
              fontWeight={500}
              letterSpacing={'0.5px'}
            >
              Still have questions? Don’t worry, we can help. Chat with an agent
              or give us a call to get the answers you need.
            </P>
            <Button
              id="Global__Footer__Button--contact"
              variant="darkBlue"
              forwardedAs={AnchorLink}
              to="/support/#contactSection"
              minWidth={['100%', '100%', '308px']}
              mx="auto"
              mb={28}
              rounded
              size="lg"
              css={`
                border: 1px solid #ffffff;
                height: 56px;
              `}
            >
              <Span
                textAlign="left"
                color="white"
                letterSpacing="0px"
                height="100%"
                display="flex"
                alignItems="center"
              >
                Contact Us
              </Span>
              <Icon src={RightArrow} ml={2} color="lightBlue800" />
            </Button>
          </Box>
          <Box height="1px" bg="blue" />
          <BottomOfFooter />
        </Container>
      </Box>
    </AffinityProvider>
  )
}

export { Footer, BottomOfFooter }
