import React, { forwardRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Box, Icon, Label, Span } from '@allied-solutions/affinity'
import { Check } from '@allied-solutions/affinity-react-icons/dist/16px'

const StyledLabel = styled(Label)`
  ${props => props.theme.typeStyles.bodyLargeCenterPrimary}
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.gray200};
  border-radius: ${props => props.theme.space[1]};
  background-color: ${props => props.theme.colors.gray20};
  position: relative;
`
const CheckContainer = styled(Box)`
  width: ${props => props.theme.space[7]};
  height: ${props => props.theme.space[7]};
  border-radius: ${props => props.theme.space[7]};
  border: 1px solid ${props => props.theme.colors.gray200};
  margin-left: ${props => props.theme.space[4]};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledRadioChecked = styled(Icon).attrs(props => ({
  color: props.color || `blue`,
  src: Check,
}))`
  transition-property: color;
`

const StyledBox = styled(Box)`
  & + & {
    margin-top: ${props => props.theme.space[4]};
  }

  ${props => props.theme.mediaQueries.md} {
    width: 48%;

    & + & {
      margin-top: ${props => props.theme.space[0]};
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    + ${StyledLabel} ${StyledRadioChecked} {
      display: none;
      visibility: hidden;
    }

    &:checked {
      + ${StyledLabel} ${StyledRadioChecked} {
        display: block;
        visibility: visible;
      }

      + ${StyledLabel} {
        border: 1px solid ${props => props.theme.colors.blue800};
        background-color: ${props => props.theme.colors.lightBlue200};
      }

      + ${StyledLabel} ${CheckContainer} {
        border: 1px solid ${props => props.theme.colors.blue800};
        background-color: white;
      }
    }

    &:disabled {
      + ${StyledLabel} {
        cursor: default;
        color: ${props => props.theme.colors.disabled};

        ${StyledRadioChecked} {
          color: ${props => props.theme.colors.disabledBg};
        }
      }
    }

    &:not(:disabled) {
      &:hover {
        + ${StyledLabel} {
          ${StyledRadioChecked} {
            display: block;
            visibility: visible;
            color: ${props => props.theme.colors.hint};
          }
        }
      }
    }
  }
`

const Radio = forwardRef(
  ({ id, name, label, className, width, textAlign, ...rest }, ref) => {
    return (
      // className attached to parent component for styled-components
      // (styles get applied to StyledBox rather than input)
      <StyledBox className={className} height={13} width={width}>
        <input ref={ref} type="radio" id={id} name={name || id} {...rest} />
        <StyledLabel htmlFor={id}>
          <Box display="flex" alignItems="center" height={13}>
            <CheckContainer>
              <StyledRadioChecked />
            </CheckContainer>
            <Span mx="auto">{label}</Span>
          </Box>
        </StyledLabel>
      </StyledBox>
    )
  }
)

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

Radio.displayName = `Radio`

Radio.Label = StyledLabel
Radio.IconChecked = StyledRadioChecked

export { Radio }
