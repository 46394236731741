import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { conformToMask } from 'react-text-mask'

import {
  Box,
  ButtonGroup,
  ProgressIndicator,
  H1,
} from '@allied-solutions/affinity'

import {
  useAddFormValuesToState,
  usePageData,
  useRedirectToEnterRefId,
  useStore,
  useSubmitInsuranceLogic,
} from '../hooks'
import { AppLayout, BackButton, NextButton, InfoBox } from './AppLayout'

import { StepNumber } from './StepNumber'
import { SubmitInsuranceLayout } from './SubmitInsuranceLayout'

const FormLayout = ({
  location,
  leftSide,
  rightSide,
  initialValues,
  mergeInitialValues = true,
  validationSchema,
  handleSubmit: handleSubmitProp,
}) => {
  useRedirectToEnterRefId()
  const { state } = useStore()
  const addFormValuesToState = useAddFormValuesToState()
  const { handleSubmit, pages } = useSubmitInsuranceLogic(location)
  const { pageSlug } = usePageData(location)

  const initialValuesFn = () => {
    if (mergeInitialValues) {
      const borrowerCopy = { ...state.borrower }
      const phoneNumber = borrowerCopy.AgentTelephone
      const phoneNumberMask = [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]
      if (phoneNumber) {
        const conformedPhoneNumber = conformToMask(
          phoneNumber,
          phoneNumberMask,
          { guide: false }
        )
        borrowerCopy.AgentTelephone = conformedPhoneNumber.conformedValue
      }
      return {
        ...initialValues,
        ...borrowerCopy,
        ...state.form,
      }
    }
    return initialValues
  }

  return (
    <SubmitInsuranceLayout location={location}>
      <Formik
        initialValues={initialValuesFn()}
        onSubmit={handleSubmitProp || handleSubmit}
        validate={addFormValuesToState}
        validationSchema={Yup.object().shape(validationSchema)}
      >
        {props => (
          <Form noValidate>
            <AppLayout
              leftSide={
                leftSide.replace ? (
                  leftSide.replace(props)
                ) : (
                  <>
                    {leftSide.subhead || (
                      <StepNumber pages={pages} location={location} />
                    )}
                    <H1 typeStyle="headlineMedium" fontWeight={900}>
                      {leftSide.title}
                    </H1>
                    {leftSide.body}
                  </>
                )
              }
              rightSide={
                rightSide.replace ? (
                  rightSide.replace(props)
                ) : (
                  <InfoBox>
                    <H1
                      className="titleSmall"
                      typeStyle="titleSmall"
                      fontWeight={900}
                      textAlign="left"
                    >
                      {rightSide.title}
                    </H1>
                    {rightSide.fields(props)}
                  </InfoBox>
                )
              }
              footerButtons={
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <ProgressIndicator
                    pages={pages}
                    currentPage={location.pathname}
                  />
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <BackButton />
                    <NextButton formikProps={props}>
                      {pageSlug === 'review' ? 'Submit' : 'Next'}
                    </NextButton>
                  </ButtonGroup>
                </Box>
              }
            />
          </Form>
        )}
      </Formik>
    </SubmitInsuranceLayout>
  )
}

export { FormLayout }
