import { useEffect } from 'react'
import { navigate } from 'gatsby'

import { useStore } from './useStore'
import { usePageData } from './usePageData'

const useRedirectToEnterRefId = () => {
  const { state } = useStore()
  const { workflowType } = usePageData()

  useEffect(() => {
    if (
      (['other', 'noReferenceId'].includes(workflowType) && !state.form?.files?.length) ||
      (!['other', 'noReferenceId'].includes(workflowType) && !state.borrower.ReferenceID)

    ) {
      return navigate('/enter-reference-id/', { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useRedirectToEnterRefId }
