import React from 'react'
import styled from 'styled-components'
import { camelCase } from 'lodash'
import { Link } from 'gatsby'
import css from '@styled-system/css'

import { Box, Icon, P, H3, H1 } from '@allied-solutions/affinity'
import { ArrowRight } from '@allied-solutions/affinity-react-icons/dist/16px'

import { TagPill } from './TagPill'

const StyledBox = styled(Box)`
  & + & {
    margin-left: ${props => props.theme.space[8]};
  }

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
`
const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  text-decoration: none;

  ${H3} {
    transition-property: color;
  }

  &:hover,
  &:focus {
    transform: translateY(-1rem);

    ${H3} {
      color: ${props => props.theme.colors.blue};
    }
  }
`

const ArticleCard = ({ title, tag, path, ...rest }) => {
  return (
    <StyledLink id={`ArticleCard--${camelCase(title)}__StyledLink`} to={path}>
      <StyledBox
        as="article"
        p={10}
        borderRadius={3}
        display="flex"
        flexDirection="column"
        boxShadow="xl"
        bg="white"
        height="100%"
        {...rest}
      >
        <Box>
          {tag && <TagPill tagName={tag} />}
          {title && (
            <H3
              typeStyle="titleLarge"
              color="gray1000"
              css={css({ mt: 11, mb: 0, color: 'darkBlue', fontWeight: 900 })}
            >
              {title}
            </H3>
          )}
        </Box>

        <Box mt="auto" pt={10} display="inline-flex" alignItems="center">
          <P mb={0} typeStyle="labelLarge" textAlign="left" color="blue1000">
            Read More
          </P>
          <Icon src={ArrowRight} color="blue800" ml={1} />
        </Box>
      </StyledBox>
    </StyledLink>
  )
}

export { ArticleCard }
