import React from 'react'

import { Box } from '@allied-solutions/affinity'

const InfoBox = ({ children }) => (
  <Box
    borderRadius={4}
    boxShadow={['none', 'none', 'none', 'xl']}
    bg="white"
    pt={[8, 8, 8, 12]}
    pb={[6, 6, 6, 14]}
    px={['8', '8', '8', '17']}
    css={`
      ${props => props.theme.mediaQueries.lg} {
        & + & {
          margin-top: ${props => props.theme.space[8]};
        }
      }
    `}
  >
    {children}
  </Box>
)

export { InfoBox }
