import { navigate } from 'gatsby'
import { kebabCase } from 'lodash'

import { useSiteMetadata } from './useSiteMetadata'
import { useAddFormValuesToDataLayer } from './useAddFormValuesToDataLayer'
import { useAddFormValuesToState } from './useAddFormValuesToState'
import { usePageData } from './usePageData'

const useSubmitInsuranceLogic = location => {
  const { submitInsurancePages } = useSiteMetadata()
  const addFormValuesToState = useAddFormValuesToState()
  const addFormValuesToDataLayer = useAddFormValuesToDataLayer()
  const { workflowType } = usePageData(location)

  const pages = submitInsurancePages[workflowType]

  const proceed = () => {
    const pageIndex = pages.indexOf(location.pathname)
    if (pageIndex + 1 === pages.length) {
      navigate(`/submit-insurance/${kebabCase(workflowType)}/confirmation/`)
    } else {
      navigate(pages[pageIndex + 1])
    }
  }

  const handleSubmit = async values => {
    await addFormValuesToState(values)
    addFormValuesToDataLayer()
    proceed()
  }

  return { handleSubmit, pages, proceed }
}

export { useSubmitInsuranceLogic }
