import React from 'react'
import { startCase } from 'lodash'
import { Pill, P } from '@allied-solutions/affinity'

const TagPill = ({ tagName, ...rest }) => (
  <Pill
    css={`
      height: ${props => props.theme.space['5']};
      padding-left: ${props => props.theme.space['3']};
      padding-right: ${props => props.theme.space['3']};
    `}
    backgroundColor="blue1000"
    justifyContent="center"
    minWidth={'61px'}
    px="3"
    {...rest}
  >
    <P
      mb={0}
      typeStyle="labelSmall"
      textAlign="center"
      color="white"
      height="100%"
      lineHeight="normal"
    >
      {startCase(tagName)}
    </P>
  </Pill>
)

export { TagPill }
