import { useEffect } from 'react'
import { navigate } from 'gatsby'

import { useStore } from './useStore'

const useDirectToFlow = () => {
  const { state } = useStore()

  useEffect(() => {
    if (state.borrower.ReferenceID) return

    if (
      state.borrower?.IsMortgage &&
      state.borrower?.MustAttachProofOfInsurance === 'true'
    ) {
      navigate('/submit-insurance/mortgage/upload-documents/', {
        replace: true,
      })
    } else if (state.borrower?.MustAttachProofOfInsurance === 'true') {
      navigate('/submit-insurance/other/upload-documents/', { replace: true })
    }
  }, [state.borrower])

  const directToFlow = () => {
    if (state.borrower?.IsMortgage) {
      return '/submit-insurance/mortgage/upload-documents/'
    }

    if (state.borrower?.MustAttachProofOfInsurance === 'true') {
      return '/submit-insurance/other/upload-documents/'
    }

    if (state.borrower?.IsVehicle) {
      return '/submit-insurance/vehicle/provider-info/'
    }

    return '/systems-down/'
  }

  return directToFlow
}

export { useDirectToFlow }
