import React from 'react'

import { Paragraph } from '@allied-solutions/affinity'

const StepNumber = ({ pages, location }) => (
  <Paragraph mb={6} typeStyle="labelMedium" textAlign="left" color="gray800">
    Step {pages.indexOf(location.pathname) + 1} of {pages.length}
  </Paragraph>
)

export { StepNumber }
