import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { use100vh } from 'react-div-100vh'
import { useClickAway } from 'react-use'

import {
  Box,
  Button,
  ButtonAnchor,
  ButtonUnstyled,
  Icon,
  Pill,
  P,
  Collapse,
  Span,
} from '@allied-solutions/affinity'
import {
  ArrowRight,
  ChevronDown,
  ChevronUp,
  Close1,
  Document3Text,
  Menu,
} from '@allied-solutions/affinity-react-icons/dist/16px'

import { theme } from '../theme.js'
import { useWindowWidth, useDisabledButtonMessaging } from '../hooks'
import { Logo } from './Logo'

const StyledBox = styled(Box)`
  position: static;
  border: 2px solid;
  border-color: ${props => props.theme.colors.muted};
  border-radius: 12px;
  box-sizing: border-box;
  padding: ${props => props.theme.space[6]};
  margin-top: ${props => props.theme.space[7]};
  cursor: pointer;

  &:first-child {
    margin-top: ${props => props.theme.space[15]};
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.darkBlue} !important;
`

const helpBreakpoint = theme[2].replace('px', '')
const Help = ({ boxShadow, ...rest }) => {
  const [needHelp, setNeedHelp] = useState(false)
  const windowWidth = useWindowWidth()
  const [liveChatOpen, setLiveChatOpen] = useState(false)
  const [callUsOpen, setCallUsOpen] = useState(false)
  const {
    liveChatDisabled,
    phoneDisabled,
    chatWillOpenTime,
    phoneWillOpenTime,
  } = useDisabledButtonMessaging()
  const menuHeight = use100vh()
  const ref = useRef(null)
  const callRef = useRef(null)
  const collapseRef = useRef()
  const collapseRefLiveChat = useRef()
  const collapseRefCall = useRef()
  useClickAway(ref, () => {
    setNeedHelp(false)
  })
  useClickAway(callRef, () => {
    setCallUsOpen(false)
  })

  useEffect(() => {
    if (needHelp) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [needHelp])

  return windowWidth >= Number(helpBreakpoint) ? (
    <Box ref={ref} position="absolute" top="0" left="0" width="100%" zIndex="2">
      <Box position="absolute" top="11" right="11" {...rest}>
        <Button
          ref={collapseRef}
          variant="lightBlue"
          id="helpMenuToggle"
          size="default"
          typeStyle="bodyMedium"
          rounded
          onClick={() => setNeedHelp(needHelp => !needHelp)}
          icon={needHelp ? Close1 : ChevronDown}
          iconPos="right"
          boxShadow={boxShadow || 'lightBlue'}
          css={`
            @media print {
              display: none;
            }
          `}
        >
          <Span fontSize="16px">{needHelp ? 'Close' : 'Help'}</Span>
        </Button>
      </Box>
      <Collapse
        id="HelpMenu__Collapse--wholeMenu"
        isOpen={needHelp}
        triggerRef={collapseRef}
        boxShadow={needHelp ? 'xl' : 'none'}
        borderBottomLeftRadius="4"
        borderBottomRightRadius="4"
        bg="white"
      >
        <Box width="100%" display="flex">
          <Box
            // bg="gray20"
            pl="15"
            pb="15"
            pr="15"
            pt="11"
            width="28%"
            minWidth="398px"
            // borderBottomLeftRadius="4"
            borderRight="1px solid"
            borderRightColor="gray80"
          >
            <Logo id="Global__Header__Logo__Secondary" />
            <Box
              width="10"
              height="10"
              gradient="blue"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="26"
              borderRadius="full"
              mb="8"
            >
              <Icon src={Document3Text} color="white" />
            </Box>
            <P
              mb={0}
              typeStyle="titleMedium"
              textAlign="left"
              fontWeight="900"
              display="block"
            >
              Find answers to the most common questions people ask.
            </P>
            <a
              id="HelpMenu__Button--Support"
              href="/support/"
              target="_blank"
              rel="noreferrer"
              css={css({
                mt: 18,
                display: 'inline-flex',
                alignItems: 'center',
                textDecoration: 'none',
              })}
            >
              <P
                mb={0}
                typeStyle="labelMedium"
                textAlign="left"
                color="blue1000"
              >
                Go to Support
              </P>
              <Icon src={ArrowRight} ml={1} />
            </a>
          </Box>
          <Box pr="15" pl="28" pt="24" pb="10" width="100%">
            <P
              mb={0}
              typeStyle="headlineMedium"
              textAlign="left"
              fontWeight="900"
            >
              We are here to help
            </P>
            <Box display="flex" mt="9">
              <Box width="50%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                    minHeight="280px"
                  >
                    <Box>
                      <P
                        mb={0}
                        typeStyle="titleLarge"
                        textAlign="left"
                        display="block"
                        fontWeight="900"
                      >
                        Live Chat
                      </P>
                      <Pill bg="lightBlue1000">
                        <P
                          mb={0}
                          typeStyle="labelSmall"
                          textAlign="center"
                          color="#FFFFFF"
                        >
                          {liveChatDisabled ? 'Closed' : 'Open'}
                        </P>
                      </Pill>
                      <P
                        mb={0}
                        typeStyle="bodyMedium"
                        textAlign="left"
                        color="gray1000"
                        mt="8"
                        display="block"
                      >
                        Mon. through Fri. <br />
                        8:00 AM - 5:00 PM EST
                      </P>
                      <P
                        mb={0}
                        typeStyle="bodyMedium"
                        textAlign="left"
                        color="gary1000"
                        mt="3"
                        display="block"
                      >
                        Closed Sat., Sun., and holidays
                      </P>
                    </Box>
                    <Box>
                      <ButtonAnchor
                        outlined
                        id="HelpMenu__Button--StartChat"
                        display="inline-block"
                        rounded
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.GATSBY_CHAT_URL}
                        disabled={liveChatDisabled}
                      >
                        {liveChatDisabled ? 'Chat Unavailable' : 'Start a Chat'}
                      </ButtonAnchor>
                    </Box>
                  </Box>
                  {liveChatDisabled && (
                    <P
                      mb={0}
                      mt="4"
                      display="block"
                      typeStyle="bodySmall"
                      textAlign="left"
                      maxWidth="200px"
                      id="liveChatClosedMessage"
                    >
                      {`Chat support will open ${chatWillOpenTime}`}
                    </P>
                  )}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  minHeight="280px"
                >
                  <Box>
                    <P
                      mb={0}
                      typeStyle="titleLarge"
                      textAlign="left"
                      display="block"
                      fontWeight="900"
                    >
                      Call Us
                    </P>
                    <Pill bg="lightBlue1000">
                      <P
                        mb={0}
                        typeStyle="labelSmall"
                        textAlign="center"
                        color="#FFFFFF"
                      >
                        {phoneDisabled ? 'Closed' : 'Open'}
                      </P>
                    </Pill>
                    <P
                      mb={0}
                      typeStyle="bodyMedium"
                      textAlign="left"
                      color="gray1000"
                      mt="8"
                      display="block"
                    >
                      Mon. through Fri. <br />
                      8:00 AM - 9:00 PM EST
                    </P>
                    <P
                      mb={0}
                      typeStyle="bodyMedium"
                      textAlign="left"
                      color="gray1000"
                      mt="3"
                      display="block"
                    >
                      Sat. 9:00 AM - 6:00 PM EST
                    </P>
                    <P
                      mb={0}
                      typeStyle="bodyMedium"
                      textAlign="left"
                      color="gray1000"
                      mt="3"
                      display="block"
                    >
                      Closed Sun. and holidays
                    </P>
                  </Box>
                  <Box>
                    <ButtonAnchor
                      outlined
                      id="HelpMenu__Button--CallUs"
                      display="inline-block"
                      rounded
                      target="_blank"
                      rel="noreferrer"
                      href="tel:(800) 998-0762"
                      disabled={phoneDisabled}
                    >
                      (800) 998-0762
                    </ButtonAnchor>
                  </Box>
                </Box>
                {phoneDisabled && (
                  <P
                    mb={0}
                    mt="4"
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    maxWidth="200px"
                    id="callSupportClosedMessage"
                  >
                    {`Call support will open ${phoneWillOpenTime}`}
                  </P>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  ) : (
    <>
      {needHelp ? (
        <Box
          position="fixed"
          top={['2rem', '2.4rem', null, null]}
          // top="4"
          right="8"
          zIndex="12"
          {...rest}
        >
          <ButtonUnstyled
            id="HelpMenu__Button--closeMobile"
            onClick={() => setNeedHelp(!needHelp)}
          >
            <Box
              width="8"
              height="8"
              borderRadius="8"
              display="flex"
              justifyContent="center"
              alignItems="center"
              gradient="blue"
            >
              <Icon src={Close1} color="white" />
            </Box>
          </ButtonUnstyled>
        </Box>
      ) : (
        <Box
          position="absolute"
          top={['22px', '6']}
          right={['17', '19']}
          width="18px"
          zIndex="12"
          css={`
            @media print {
              display: none;
            }
          `}
        >
          <ButtonUnstyled
            typeStyle="bodyMedium"
            id="helpMenuToggle"
            position="fixed"
            ref={collapseRef}
            top={['2.1rem', '2.65rem', null, null]}
            onClick={() => setNeedHelp(!needHelp)}
          >
            Help
            <Icon ml="2" src={Menu} />
          </ButtonUnstyled>
        </Box>
      )}
      <Box position="fixed" top="0" left="0" right="0" zIndex="11">
        <Collapse
          id="HelpMenu__Collapse--wholeMenu"
          isOpen={needHelp}
          triggerRef={collapseRef}
        >
          <Box
            px={['6', '8']}
            // pt={5}
            pt={['2.2rem', '2.57rem', '2.56rem', 5]}
            pl={['2rem', null, null, null]}
            pb="20"
            top="0"
            left="0"
            bg="white"
            height={`${menuHeight}px`}
            overflow="auto"
            zIndex="7"
          >
            <Logo id="Global__Header__Logo__Help" />
            <P
              mb={0}
              typeStyle="displayMedium"
              textAlign="center"
              display="block"
              mt="19"
              px="7"
              fontWeight="900"
            >
              We are here to&nbsp;help
            </P>
            <P
              mb={0}
              typeStyle="titleMedium"
              fontWeight={400}
              lineHeight="32px"
              textAlign="center"
              mt="7"
              display="block"
            >
              Still have questions? Don’t worry, we can help. Chat with an agent
              or give us a call to get the answers you need.
            </P>
            <StyledLink to="/support/" id="HelpMenu__Button--Support">
              <StyledBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  textAlign="left"
                  fontWeight="900"
                >
                  Read FAQs
                </P>
                <Icon
                  color="lightBlue"
                  src={ChevronDown}
                  css={`
                    transform: rotate(-90deg);
                  `}
                />
              </StyledBox>
            </StyledLink>
            <StyledBox>
              <Box
                id="mobileLiveChatOpenToggle"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                ref={collapseRefLiveChat}
                onClick={() => setLiveChatOpen(!liveChatOpen)}
              >
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  textAlign="left"
                  fontWeight="900"
                >
                  Live Chat
                </P>
                <Icon
                  src={liveChatOpen ? ChevronUp : ChevronDown}
                  color="blue800"
                />
              </Box>

              <Collapse
                id="HelpMenu__Collapse--liveChat"
                triggerRef={collapseRefLiveChat}
                isOpen={liveChatOpen}
              >
                <Pill bg="lightBlue200">
                  <P mb={0} typeStyle="caption" textAlign="center" color="info">
                    {liveChatDisabled ? 'Closed' : 'Open'}
                  </P>
                </Pill>
                <P
                  mb={0}
                  typeStyle="bodyMedium"
                  textAlign="left"
                  color="gray1000"
                  mt="5"
                  display="block"
                >
                  Mon. through Fri. <br />
                  8:00 AM - 5:00 PM EST
                </P>
                <P
                  mb={0}
                  typeStyle="bodyMedium"
                  textAlign="left"
                  color="gray1000"
                  mt="3"
                  display="block"
                >
                  Closed Sat., Sun., and holidays
                </P>
                <ButtonAnchor
                  mt="10"
                  outlined
                  id="HelpMenu__Button--StartChat"
                  display="inline-block"
                  rounded
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.GATSBY_CHAT_URL}
                  disabled={liveChatDisabled}
                >
                  {liveChatDisabled ? 'Chat Unavailable' : 'Start a Chat'}
                </ButtonAnchor>
                {liveChatDisabled && (
                  <P
                    mb={0}
                    mt="4"
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    id="liveChatClosedMessage"
                  >
                    {`Chat support will reopen ${chatWillOpenTime}`}
                  </P>
                )}
              </Collapse>
            </StyledBox>
            <StyledBox>
              <Box
                id="mobileCallOpenToggle"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ref={collapseRefCall}
                onClick={() => setCallUsOpen(!callUsOpen)}
              >
                <P
                  mb={0}
                  typeStyle="titleMedium"
                  textAlign="left"
                  fontWeight="900"
                >
                  Call Us
                </P>
                <Icon
                  src={callUsOpen ? ChevronUp : ChevronDown}
                  color="blue800"
                />
              </Box>
              <Collapse
                id="HelpMenu__Collapse--callUs"
                triggerRef={collapseRefCall}
                isOpen={callUsOpen}
              >
                <Pill bg="lightBlue200">
                  <P mb={0} typeStyle="caption" textAlign="center" color="info">
                    {phoneDisabled ? 'Closed' : 'Open'}
                  </P>
                </Pill>
                <P
                  mb={0}
                  typeStyle="bodyMedium"
                  textAlign="left"
                  color="gray1000"
                  mt="5"
                  display="block"
                >
                  Mon. through Fri. <br />
                  8:00 AM - 9:00 PM EST
                </P>
                <P
                  mb={0}
                  typeStyle="bodyMedium"
                  textAlign="left"
                  color="gray1000"
                  mt="3"
                  display="block"
                >
                  Sat. 9:00 AM - 6:00 PM EST
                </P>
                <P
                  mb={0}
                  typeStyle="bodyMedium"
                  textAlign="left"
                  color="gray1000"
                  mt="3"
                  display="block"
                >
                  Closed Sun. and holidays
                </P>
                <ButtonAnchor
                  mt="10"
                  outlined
                  id="HelpMenu__Button--CallUs"
                  display="inline-block"
                  rounded
                  target="_blank"
                  rel="noreferrer"
                  href="tel:(800) 998-0762"
                  disabled={phoneDisabled}
                >
                  (800) 998-0762
                </ButtonAnchor>
                {phoneDisabled && (
                  <P
                    mb={0}
                    mt="4"
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    id="callSupportClosedMessage"
                  >
                    {`Call support will reopen ${phoneWillOpenTime}`}
                  </P>
                )}
              </Collapse>
            </StyledBox>
          </Box>
        </Collapse>
      </Box>
    </>
  )
}

export { Help }
