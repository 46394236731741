import React from 'react'
import { navigate } from 'gatsby'

import { ButtonUnstyled } from '@allied-solutions/affinity'

const BackButton = props => (
  <ButtonUnstyled
    id="SubmitInsurance__Button--back"
    onClick={() => navigate(-1)}
    typeStyle="labelLarge"
    display="flex"
    justifyContent="center"
    color="darkGray"
    {...props}
  >
    Back
  </ButtonUnstyled>
)

export { BackButton }
