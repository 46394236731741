import React from 'react'
import { css } from '@styled-system/css'

import { Box, ButtonAnchor, Pill, P, H3 } from '@allied-solutions/affinity'

import { theme } from '../theme.js'
import { useWindowWidth, useDisabledButtonMessaging } from '../hooks'

const ContactInfo = () => {
  const windowWidth = useWindowWidth()
  const mediumBreakpoint = theme[1].replace('px', '')
  const {
    liveChatDisabled,
    phoneDisabled,
    chatWillOpenTime,
    phoneWillOpenTime,
  } = useDisabledButtonMessaging()

  return (
    <Box
      display={[null, null, 'flex']}
      justifyContent="space-around"
      mt={[null, null, 20]}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <H3
              className="h4"
              typeStyle="h4"
              css={css({ mt: 7, textAlign: 'center' })}
            >
              Live Chat
            </H3>
            <Pill bg="lightBlue1000">
              <P
                mb={0}
                typeStyle="labelSmall"
                textAlign="center"
                color="#FFFFFF"
              >
                {liveChatDisabled ? 'Closed' : 'Open'}
              </P>
            </Pill>
            <P
              mb={0}
              typeStyle="bodySmall"
              textAlign="center"
              color="secondary"
              display="block"
              mt="6"
            >
              Mon. through Fri. 8:00 AM - 5:00 PM EST
              <br />
              Closed Sat., Sun., and holidays
            </P>
          </Box>
          <Box textAlign="center" mt="12">
            <ButtonAnchor
              id="Error__Button--chat"
              target="_blank"
              rel="noreferrer"
              outlined
              rounded
              href={process.env.GATSBY_CHAT_URL}
              variant="blue1000"
              disabled={liveChatDisabled}
            >
              {liveChatDisabled ? 'Chat Unavailable' : 'Start a Chat'}
            </ButtonAnchor>
          </Box>
        </Box>
        {liveChatDisabled && (
          <P
            mb={0}
            mt="4"
            display="block"
            typeStyle="bodySmall"
            textAlign="center"
            id="Error__Text--chatClosedMessage"
          >
            {`Chat support will open ${chatWillOpenTime}`}
          </P>
        )}
      </Box>
      {windowWidth < mediumBreakpoint ? (
        <Box position="relative" mt="20" mb="12">
          <Box height="2px" bg="gray1000" />
          <Box
            position="absolute"
            top="-20px"
            left="calc(50% - 20px)"
            width="10"
            height="10"
            borderRadius="full"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            bg="gray1000"
          >
            <P mb={0} typeStyle="bodyMedium" textAlign="center" color="white">
              OR
            </P>
          </Box>
        </Box>
      ) : (
        <Box position="relative" px="8">
          <Box width="2px" height="100%" bg="gray1000" />
          <Box
            position="absolute"
            top="calc(50% - 20px)"
            left="14px"
            width="10"
            height="10"
            borderRadius="full"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            bg="gray1000"
          >
            <P mb={0} typeStyle="bodyMedium" textAlign="center" color="white">
              OR
            </P>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <H3
            className="h4"
            typeStyle="h4"
            css={css({ mt: 7, textAlign: 'center' })}
          >
            Call Us
          </H3>
          <Pill bg="lightBlue1000">
            <P mb={0} typeStyle="labelSmall" textAlign="center" color="#FFFFFF">
              {phoneDisabled ? 'Closed' : 'Open'}
            </P>
          </Pill>
          <P
            mb={0}
            typeStyle="bodySmall"
            textAlign="center"
            color="secondary"
            display="block"
            mt="6"
          >
            Mon. through Fri. 8:00 AM - 9:00 PM EST
            <br />
            Sat. 9:00 AM - 6:00 PM EST
            <br />
            Closed Sun. and on holidays
          </P>
        </Box>
        <Box textAlign="center" mt="7">
          <ButtonAnchor
            id="Error__Button--call"
            outlined
            rounded
            href="tel:(800) 998-0762"
            color="blue1000"
            disabled={phoneDisabled}
          >
            (800) 998-0762
          </ButtonAnchor>
        </Box>
        {phoneDisabled && (
          <P
            mb={0}
            mt="4"
            display="block"
            typeStyle="bodySmall"
            textAlign="center"
            id="Error__Text--callSupportClosedMessage"
          >
            {`Call support will open ${phoneWillOpenTime}`}
          </P>
        )}
      </Box>
    </Box>
  )
}

export { ContactInfo }
