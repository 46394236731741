import React from 'react'

import { H3, H2 } from '@allied-solutions/affinity'

import { useStore } from '../hooks'
import { InfoBox } from './AppLayout'
import { Name, CoBorrower, Collateral } from './BorrowerInfo'

const ConfirmBorrowerInfo = () => {
  const { state } = useStore()

  return (
    state.borrower.ReferenceID && (
      <>
        <InfoBox>
          <H2
            className="titleSmall"
            typeStyle="titleSmall"
            fontWeight={900}
            textAlign="left"
          >
            Borrower Information
          </H2>
          <Name color="hint" />
        </InfoBox>
        <InfoBox mt={['0', '0', '0', '12']}>
          <H2
            className="titleSmall"
            typeStyle="titleSmall"
            fontWeight={900}
            textAlign="left"
          >
            Collateral
          </H2>
          <Collateral />
        </InfoBox>
      </>
    )
  )
}

export { ConfirmBorrowerInfo }
