import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Logo } from './Logo'
import { Help } from './Help'

import { Box } from '@allied-solutions/affinity'

import { theme } from '../theme.js'
import { useWindowWidth } from '../hooks'

const StyledNav = styled.nav`
  & .colorLi {
    color: ${props => props.theme.colors.gray1000};
  }
`
// const StyledButton = styled(Button)`
//   display: block;
//   width: 100%;
// `

const mediumBreakpoint = Number(theme[1].replace('px', ''))

const NoRefIdNav = ({ noHelpButton }) => {
  const windowWidth = useWindowWidth()
  const [needMenu, setNeedMenu] = useState(false)

  useEffect(() => {
    if (needMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [needMenu])

  useEffect(() => {
    if (windowWidth > mediumBreakpoint && needMenu) {
      setNeedMenu(false)
    }
  }, [windowWidth, needMenu])

  return (
    <StyledNav>
      <Box
        px={[6, 6, 8, 15]}
        pt={[5, '1.06rem', 5, 6]}
        pb={[4, 4, 4, 6]}
        padding={['1.94rem 2rem', null, '2.3rem 2rem', null]}
        pl={[null, '2rem', null, null]}
        id="homeSupportNav"
        display="flex"
        position="fixed"
        top="0"
        right="0"
        left="0"
        zIndex="100"
        bg="white"
        borderBottom="1px solid"
        borderColor="muted"
        height={['85px', '100px', null, null]}
        css={`
          @media print {
            display: block;
          }
        `}
      >
        <Logo
          id="Global__Header__Logo__NoRef"
          css={`
            @media print {
              img {
                display: block;
                margin: 0 auto;
              }
            }
          `}
        />
        {noHelpButton ? null : (
          <Help
            boxShadow="none"
            right={[8, 8, 8, 15]}
            // top="18px"
            top={['1.6rem', '2.1rem', null, null]}
          />
        )}
      </Box>
    </StyledNav>
  )
}

export { NoRefIdNav }
