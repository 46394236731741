import React from 'react'

import { P } from '@allied-solutions/affinity'

import { useStore } from '../../hooks'
import { StyledUl } from './StyledUl'

const Name = ({ color }) => {
  const { state } = useStore()
  return (
    <StyledUl>
      <li>
        <P
          mb={0}
          display="block"
          typeStyle="labelSmall"
          textAlign="left"
          color={color || 'blue800'}
        >
          First Name
        </P>
        <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
          {state.borrower.PolicyholderFirstName}
          {
            state.borrower.AdditionalOwners && state.borrower.AdditionalOwners.map((borrower) => {
                return (
                    <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
                        {borrower.FirstName}
                    </P>
                )
            }
            )
        }
        </P>
      </li>
      <li>
        <P
          mb={0}
          display="block"
          typeStyle="labelSmall"
          textAlign="left"
          color={color || 'blue800'}
        >
          Last Name
        </P>
        <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
          {state.borrower.PolicyholderLastName}
          {
            state.borrower.AdditionalOwners && state.borrower.AdditionalOwners.map((borrower) => {
                return (
                    <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
                        {borrower.LastName}
                    </P>
                )
            }
            )
        }
        </P>
      </li>
    </StyledUl>
  )
}

export { Name }
