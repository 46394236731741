import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { isEqual } from 'lodash'

import { Box, Input, Span, P } from '@allied-solutions/affinity'

import { fileUploaderContext } from './FileUploader'

const StyledInput = styled(Input)`
  display: none;
`

const StyledSpan = styled(Span)`
  color: ${props => props.theme.colors.blue800};
  text-decoration: underline;
`

const DropContainer = props => (
  <Box
    minHeight="200px"
    bg="gray20"
    borderRadius={2}
    border="1px dashed"
    borderColor="gray200"
    display="flex"
    flexDirection="column"
    justifyContent="flex-end"
    textAlign="center"
    tabIndex={0}
    p={6}
    pt={15}
    css={`
      cursor: pointer;
    `}
    {...props}
  />
)

const types = {
  'application/pdf': '.pdf',
  'image/jpeg': '.jpg',
  'image/tiff': '.tif',
}

const changeExtension = file => {
  return file.name.substr(0, file.name.lastIndexOf('.')) + types[file.type]
}

const removeResizedString = fileName => {
  return fileName.replace('Resized_', 'Rsz_')
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const allToBase64 = files => {
  return Promise.all(
    files.map(async file => {
      const fileBlob = new Blob([file])
      let convertedFile = await toBase64(fileBlob)
      convertedFile = convertedFile.replace(/^data:(.*);base64,/, '')
      return {
        name: removeResizedString(changeExtension(file)),
        base64: convertedFile,
        type: file.type,
      }
    })
  )
}

const removeDupes = files => {
  const seen = new Set()

  return files.filter(file => {
    const duplicate = seen.has(file.name)
    if (duplicate)
      alert('Duplicate file names detected. Only keeping the first one.')
    seen.add(file.name)
    return !duplicate
  })
}

const Control = ({ accept, size = 10000000, maxLength = 5, ...rest }) => {
  const { files, setFiles } = useContext(fileUploaderContext)

  const [dragging, setDragging] = useState(false)

  const labelRef = useRef()

  const acceptMimeArray = accept.split(',').map(i => i.trim())
  console.log(acceptMimeArray);
  const acceptExtensionsArray = acceptMimeArray.map(i => types[i])
  console.log(acceptExtensionsArray);
  const acceptString =
    acceptExtensionsArray.slice(0, -1).join(', ') +
    ' or ' +
    acceptExtensionsArray.slice(-1)
  const niceSize = `${size / 1000000} MB`

  const validateFiles = files => {
    return files.filter((file, index) => {
      console.log(file.type);
      console.log(file.size);
      if (file.size <= size && acceptMimeArray.includes(file.type)) {
        return true
      } else {
        alert(
          `${file.name} not supported. Please ensure size is less than ${niceSize} and type is either ${acceptString}.`
        )
        return false
      }
    })
  }

  // remove duplicates and ensure max of 5 uploads
  useEffect(() => {
    const filesCopy = removeDupes([...files])

    if (filesCopy.length > maxLength) {
      alert(`A maximum of ${maxLength} files may be selected.`)
      filesCopy.splice(maxLength)
    }

    if (!isEqual(files, filesCopy)) setFiles(filesCopy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, maxLength])

  const handleFileChange = event => {
    console.log('file uploader - on change')
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'file uploader - on change',
      })
    }

    let selectedFiles = [...event.target.files]
    allToBase64(validateFiles(selectedFiles)).then(data =>
      setFiles([...files, ...data])
    )

    event.target.value = null
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      labelRef.current.click()
    }
  }

  const onDragOver = isOver => event => {
    event.stopPropagation()
    event.preventDefault()
    setDragging(isOver)
  }

  const onDrop = event => {
    event.stopPropagation()
    event.preventDefault()

    //console.log('file uploader - on drop')
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'file uploader - on drop',
      })
    }

    let rawFiles = [...event.dataTransfer.files]
    allToBase64(validateFiles(rawFiles)).then(data =>
      setFiles([...files, ...data])
    )
    setDragging(false)
  }

  return (
    <>
      <DropContainer
        onDragEnter={onDragOver(true)}
        onDragLeave={onDragOver(false)}
        onDragOver={onDragOver(true)}
        onDrop={onDrop}
        onClick={() => labelRef.current.click()}
        onKeyPress={handleKeyPress} // Added onKeyPress event handler
      >
        {!dragging && (
          <>
            <label
              htmlFor="fileUpload"
              ref={labelRef}
              onClick={e => e.stopPropagation()}
              css={`
                display: block;
                cursor: pointer;
                ${props => props.theme.typeStyles.bodyLargeCenterPrimary}
              `}
            >
              Drag and drop a file to
              <br />
              upload or <StyledSpan>browse my files</StyledSpan>
            </label>
            <StyledInput
              type="file"
              accept={accept}
              onChange={handleFileChange}
              {...rest}
            />
            <P
              mb={0}
              typeStyle="caption"
              textAlign="center"
              color="gray1000"
              display="block"
              mt={9}
            >
              Supported file types: {acceptString}. | no larger than {niceSize}
            </P>
          </>
        )}
      </DropContainer>
    </>
  )
}

export { Control }
