import React from 'react'
import { Icon, Tooltip, Box } from '@allied-solutions/affinity'
import { Info } from '@allied-solutions/affinity-react-icons/dist/12px'

const Hint = props => (
  <Tooltip {...props}>
    <Box display="inline-flex" alignItems="center">
      <Icon src={Info} color="darkGray" />
    </Box>
  </Tooltip>
)

export { Hint }
