import { useStore } from './useStore'

const useFileRemove = () => {
  const { state, dispatch } = useStore()

  const handleRemoveClick = file => {
    const filesCopy = [...state.form.files]
    filesCopy.splice(state.form.files.indexOf(file), 1)
    dispatch({
      type: 'addToState',
      payload: { form: { ...state.form, files: filesCopy } },
    })
  }

  return handleRemoveClick
}

export { useFileRemove }
