import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Flex } from '@allied-solutions/affinity'

import { ArticleCard } from './ArticleCard'
import { camelCase } from 'lodash'

const ArticleGrid = ({ ...rest }) => {
  const { articles } = useStaticQuery(graphql`
    {
      articles: allMdx(
        filter: { fileAbsolutePath: { glob: "**/support/*" } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            frontmatter {
              path
              tag
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Flex display={[null, null, null, 'flex']} {...rest}>
      {articles?.edges?.map(({ node }) => (
        <Flex.Col
          key={`ArticleCard--${camelCase(node.frontmatter.title)}`}
          css={`
            & + & {
              margin-top: ${props => props.theme.space[8]};
            }

            ${props => props.theme.mediaQueries.lg} {
              & + & {
                margin-top: 0;
              }
            }
          `}
        >
          <ArticleCard {...node.frontmatter} />
        </Flex.Col>
      ))}
    </Flex>
  )
}

export { ArticleGrid }
