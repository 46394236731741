import { camelCase } from 'lodash'

const usePageData = location => {
  const pathname = location
    ? location.pathname
    : typeof window !== 'undefined'
    ? window.location.pathname
    : ''
  const urlParts = pathname.split('/').filter(part => !!part)
  // console.log('urlParts', urlParts)
  const workflowType = camelCase(urlParts[1])
  const pageSlug = urlParts[2]

  return { workflowType, pageSlug }
}

export { usePageData }
