import React from 'react'

import { P } from '@allied-solutions/affinity'

import { useStore } from '../../hooks'
import { StyledUl } from './StyledUl'

const Collateral = ({ showFullAddress }) => {
  const { state } = useStore()
  return (
    <StyledUl>
      {state.borrower.IsVehicle ? (
        <>
          <li>
            <P
              mb={0}
              display="block"
              typeStyle="labelSmall"
              textAlign="left"
              color="hint"
            >
              Make
            </P>
            <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
              {state.borrower.Make}
            </P>
          </li>
          <li>
            <P
              mb={0}
              display="block"
              typeStyle="labelSmall"
              textAlign="left"
              color="hint"
            >
              Model
            </P>
            <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
              {state.borrower.Model}
            </P>
          </li>
          <li>
            <P
              mb={0}
              display="block"
              typeStyle="labelSmall"
              textAlign="left"
              color="hint"
            >
              Year
            </P>
            <P mb={0} display="block" typeStyle="bodyLarge" textAlign="left">
              {state.borrower.Year}
            </P>
          </li>
        </>
      ) : (
        <li
          css={`
            min-width: 100%;
          `}
        >
          <P
            mb={0}
            display="block"
            typeStyle="labelSmall"
            textAlign="left"
            color="hint"
          >
            {state.borrower.IsMortgage ? 'Address' : 'Collateral'}
          </P>
          <P mb={0} display="block" typeStyle="bodyMedium" textAlign="left">
            {showFullAddress
              ? state.borrower.FullAddress
              : state.borrower.PropertyDescription}
          </P>
        </li>
      )}
    </StyledUl>
  )
}

export { Collateral }
