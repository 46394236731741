import React from 'react'
import { navigate } from 'gatsby'
import { css } from '@styled-system/css'

import { LinkAnchor } from '../components/LinkAnchor'

import {
  Box,
  Button,
  Icon,
  Paragraph,
  P,
  H1,
  H2,
  H3,
  ButtonUnstyled,
} from '@allied-solutions/affinity'
import { ArrowRight } from '@allied-solutions/affinity-react-icons/dist/16px'

import { formatDate } from '../functions'
import {
  usePageData,
  useRedirectToEnterRefId,
  useStore,
  useWindowWidth,
} from '../hooks'
import { theme } from '../theme.js'
import { AppLayout, InfoBox } from './AppLayout'
import { ButtonGroup } from './ButtonGroup'
import { SubmitInsuranceLayout } from './SubmitInsuranceLayout'
import { TagPill } from './TagPill'

const Confirmation = ({ location, data }) => {
  const { dispatch, state } = useStore()
  useRedirectToEnterRefId()
  const { workflowType } = usePageData(location)
  const windowWidth = useWindowWidth()
  const mediumBreakpoint = theme[1].replace('px', '')

  return (
    // utilizing 'main' here causes duplication of landmark use, SubmitInsuranceLayout utilzes main in the Helment
    <SubmitInsuranceLayout location={location}>
      {/* <main> */}
      <AppLayout
        leftSide={
          <>
            <Paragraph
              mb={6}
              typeStyle="labelLarge"
              textAlign="left"
              color="gray600"
            >
              Thank you!
            </Paragraph>
            <H1 className="h4" typeStyle="h4">
              We successfully received your information.
            </H1>
            {windowWidth < mediumBreakpoint ? (
              <Paragraph
                typeStyle="bodyMedium"
                textAlign="left"
                color="gray600"
                mb={14}
              >
                {state?.form?.PolicyholderEmailAddress
                  ? `A confirmation email will be delivered to ${state.form.PolicyholderEmailAddress}. You may now take a screenshot of your confirmation or close this window.`
                  : `You're all set. You may now take a screenshot of your confirmation or close this window.`}
              </Paragraph>
            ) : (
              <Paragraph
                typeStyle="bodyMedium"
                textAlign="left"
                color="gray600"
                mb={14}
              >
                {state?.form?.PolicyholderEmailAddress
                  ? `A confirmation email will be delivered to ${state.form.PolicyholderEmailAddress}. You may now close this window.`
                  : `You're all set. You may now print your confirmation or close this window.`}
              </Paragraph>
            )}
            <LinkAnchor
              id={`ArticleCard--whatHappensNext__StyledLink`}
              to="/support/#iSubmittedMyInformationWhatHappensNext"
              css={`
                display: block;
              `}
            >
              <Box
                p={10}
                borderRadius={3}
                display="flex"
                flexDirection="column"
                border="1px solid"
                borderColor="gray90"
                bg="white"
              >
                <Box>
                  <TagPill tagName="help" />
                  <H2
                    typeStyle="titleLarge"
                    color="gray1000"
                    css={css({
                      mt: 11,
                      mb: 0,
                      color: 'darkBlue',
                      fontWeight: 900,
                    })}
                  >
                    Learn what happens after you submit your insurance.
                  </H2>
                </Box>

                <Box
                  mt="auto"
                  pt={15}
                  display="inline-flex"
                  alignItems="center"
                >
                  <P typeStyle="labelLarge" textAlign="left" color="blue1000">
                    Learn More
                  </P>
                  <Icon
                    src={ArrowRight}
                    color="blue800"
                    paddingBottom={4}
                    ml={1}
                  />
                </Box>
              </Box>
            </LinkAnchor>
          </>
        }
        rightSide={
          <>
            <InfoBox>
              {(state.borrower.ReferenceID ||
                workflowType === 'other' ||
                workflowType === 'noReferenceId') && (
                <>
                  <H3
                    className="titleSmall"
                    typeStyle="titleSmall"
                    fontWeight={900}
                    textAlign="left"
                  >
                    Confirmation
                    <P
                      display={['block', 'block', 'block', 'inline-block']}
                      ml={[null, '3']}
                      typeStyle="caption"
                      textAlign="left"
                      color="hint"
                    >
                      submitted on {formatDate(state.borrower.Date)}
                    </P>
                  </H3>
                  {data}
                </>
              )}
            </InfoBox>
          </>
        }
        footerButtons={
          <ButtonGroup display="flex" justifyContent="flex-end">
            {windowWidth < mediumBreakpoint ? null : (
              <ButtonUnstyled
                id="SubmitInsurance__Confirm__Button--print"
                // as={Button}
                typeStyle="labelLarge"
                textAlign="right"
                color="gray600"
                onClick={() => window.print()}
              >
                Print Confirmation
              </ButtonUnstyled>
            )}
            <Button
              id="SubmitInsurance__Confirm__Button--close"
              minWidth={24}
              ml={['5', '8']}
              rounded
              size="md"
              onClick={() => {
                dispatch({ type: 'resetForm' })
                navigate('/')
              }}
            >
              Close
            </Button>
          </ButtonGroup>
        }
      />
      {/* </main> */}
    </SubmitInsuranceLayout>
  )
}

export { Confirmation }
