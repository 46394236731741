import { useEffect } from 'react'
import { isEqual, pickBy } from 'lodash'
import { usePrevious } from 'react-use'

import { usePageData } from './usePageData'
import { useStore } from './useStore'

const useDataLayerPushChanges = location => {
  const { state } = useStore()
  const previous = usePrevious(state.form)

  const { pageSlug } = usePageData(location)

  useEffect(() => {
    // console.log(previous)
    if (typeof dataLayer === 'undefined' || !previous) return

    let chosen = pickBy(
      state.form,
      (value, key) => !isEqual(value, previous[key]) && value !== ''
    )

    Object.entries(chosen).forEach(([key]) => {
      console.log(`${pageSlug} - field change - ${key}`)
      dataLayer.push({
        event: `${pageSlug} - field change - ${key}`,
      })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.form])
}

export { useDataLayerPushChanges }
