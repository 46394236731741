import { useRef } from 'react'
import { navigate } from 'gatsby'

import { useStore } from './useStore'

const isSiteOnline = (url, callback) => {
  // try to load favicon
  var timer = setTimeout(function () {
    // timeout after 2 seconds
    callback(false)
  }, 2000)

  var img = document.createElement('img')
  img.onload = function () {
    clearTimeout(timer)
    callback(true)
  }

  img.onerror = function () {
    clearTimeout(timer)
    callback(false)
  }

  img.src = url + '/favicon.ico'
}

const maybeRedirectToBrandedSite = (refId, returnUrl) => {
  isSiteOnline('https://www1.myinsuranceinfo.com', found => {
    if (typeof window !== 'undefined' && found) {
      let url = 'https://www1.myinsuranceinfo.com/'
      if (window.location.search) {
        url += window.location.search
      } else if (refId) {
        url += '?id=' + refId
      }
      navigate(url, { replace: true })
    } else {
      navigate(returnUrl)
    }
  })
}

const useFetchBorrower = returnUrl => {
  const submitCount = useRef(0)
  const { state, dispatch } = useStore()

  const errorMsgs = [
    `Nothing found for this Reference ID.`,
    `Something went wrong. Please contact support or try again later.`,
    `Our systems are down. Please try again at a later time.`,
  ]

  const getBorrower = refId => {
    refId = refId.replace(/-/g, '')
    return (
      fetch(`${process.env.GATSBY_API_URL}/insurance-document?ReferenceId=${refId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true'
        }
      })  
        // handle HTTP status codes
        .then(res => {
          if (res.ok) return res.json()

          if (res.status >= 300 && res.status < 500) {
            throw new Error(errorMsgs[0])
          }

          if (res.status === 503) {
            throw new Error(errorMsgs[2])
          }

          if (res.status >= 500) {
            throw new Error(errorMsgs[1])
          }
        })
        // success
        .then(async data => {
          if (
            data.RequiredCoverageType === 'HAZARD' &&
            (data.IsMortgage === 'true' || data.IsMortgage === true)
          ) {
            data.MustAttachProofOfInsurance = true
          }

          //console.log(data)
          dispatch({
            type: 'addToState',
            payload: {
              form: { refId },
              borrower: data,
            },
          })

          // branding lookup
          const res = await fetch(
            `${process.env.GATSBY_API_URL}/branding?LenderCode=${data.LenderCode}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            }
          )

          if(res.status === 204)
          {
            return null;
          }
          return await res.json()
        })
        .then(async data => {
          // if branded, go to the old site
          if (
            data && data.ClientName &&
            data.Color &&
            data.LogoFileName
          ) {
            maybeRedirectToBrandedSite(refId, returnUrl)
          } else {
            navigate(returnUrl)
          }
        })
    )
  }

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(true)
    submitCount.current++

    getBorrower(values.refId)
      // error
      .catch(error => {
        console.error(error)
        if (error.message !== errorMsgs[0]) {
          if (submitCount.current !== 3) {
            handleSubmit(values, bag)
            return
          }
          submitCount.current = 0

          if (error.message === errorMsgs[2]) {
            navigate('/systems-down/')
            return
          }

          navigate('/systems-down/')
        }
        bag.setErrors({
          refId: error.message,
        })
        bag.setSubmitting(false)
      })
  }

  return {
    getBorrower,
    handleSubmit,
  }
}

export { useFetchBorrower }
