import React from 'react'

import { Box, Icon, Paragraph } from '@allied-solutions/affinity'
import { Resource } from '@allied-solutions/affinity-react-icons/dist/16px'

const Notice = ({ children, ...rest }) => (
  <Box display="flex" alignItems="center" {...rest}>
    <Icon
      display="flex"
      alignItems="center"
      justifyContent="center"
      size={9}
      bg="lightBlue200"
      borderRadius="50%"
      gradient="blue"
      src={Resource}
    />
    <Box flex="1" ml={5}>
      <Paragraph mb={0} typeStyle="bodyMedium" textAlign="left">
        {children}
      </Paragraph>
    </Box>
  </Box>
)

export { Notice }
