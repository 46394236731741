const leadZero = num => String(num).padStart(2, '0')

const formatDate = date => {
  date = date ? new Date(date) : new Date()

  return (
    leadZero(date.getMonth() + 1) +
    '/' +
    leadZero(date.getDate()) +
    '/' +
    date.getFullYear()
  )
}

export { formatDate }
