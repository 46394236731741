import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { Layout } from './Layout'

const SubmitInsuranceLayout = ({ children }) => {
  // warn user before leaving that they may lose progress
  useEffect(() => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    const trident = ua.indexOf('Trident/')

    if (msie < 0 && trident < 0) {
      window.addEventListener('beforeunload', e => {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = ''
      })
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Verify My Insurance</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main>{children}</main>
    </Layout>
  )
}

export { SubmitInsuranceLayout }
