import React from 'react'
import { Link } from 'gatsby'

import logo from './MyInsuranceInfo.svg'
import logoWhite from './MyInsuranceInfo-white.svg'

const Logo = props => {
  const { white, className, id, ...rest } = props
  return (
    <Link
      id={id || `Global__Header__Logo`}
      className={className}
      tabIndex={id === 'MyInsuranceInfo__Header__Logo' && '0'}
      css={`
        display: flex;
        align-items: center;
        width: 170px;

        ${props => props.theme.mediaQueries.sm} {
          width: 207px;
        }
      `}
      to="/"
    >
      <img
        src={white ? logoWhite : logo}
        alt="MyInsuranceInfo logo"
        width="207"
        height="28"
        {...rest}
      />
    </Link>
  )
}

export { Logo }
