import { useStore } from './useStore'

const useAddFormValuesToDataLayer = () => {
  const { state } = useStore()

  const addFormValuesToDataLayer = () => {
    if (typeof dataLayer === 'undefined') return

    const {
      AgentName,
      AgentTelephone,
      AgentEmailAddress,
      IsAgent,
      CollRadio,
      CollDeductible,
      CompRadio,
      CompDeductible,
      LienholderRadio,
      // CoverageExcludesWind,
      // MortgageeListed,
      files,
      PolicyholderEmailAddress,
    } = state?.form

    return dataLayer.push({
      event: 'custom form submit',
      AgentNameIncluded: !!AgentName,
      AgentTelephoneIncluded: !!AgentTelephone,
      AgentEmailAddressIncluded: !!AgentEmailAddress,
      IsAgent,
      CollRadio: CollRadio === 'true',
      CollDeductible,
      CompRadio: CompRadio === 'true',
      CompDeductible,
      LienholderRadio: LienholderRadio === 'true',
      // CoverageExcludesWind: CoverageExcludesWind === 'true',
      // MortgageeListed: MortgageeListed === 'true',
      FilesCount: !!files ? files.length : 0,
      PolicyholderEmailAddressIncluded: !!PolicyholderEmailAddress,
    })

    // debugging
    // return console.log(dataLayer)
  }

  return addFormValuesToDataLayer
}

export { useAddFormValuesToDataLayer }
