import { useState, useEffect } from 'react'

import { useStore } from './useStore'
import { useSubmitInsuranceLogic } from './useSubmitInsuranceLogic'
import { useAddFormValuesToDataLayer } from './useAddFormValuesToDataLayer'

const useMustAttachLogic = location => {
  const { state } = useStore()
  const { proceed } = useSubmitInsuranceLogic(location)
  const addFormValuesToDataLayer = useAddFormValuesToDataLayer()

  const [isValid, setIsValid] = useState(true)
  const [isTouched, setIsTouched] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isTouched) validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.form?.files, isTouched])

  useEffect(() => {
    if (isValid && isTouched && isSubmitting) {
      addFormValuesToDataLayer()
      proceed()
    } else {
      setIsSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isTouched, isSubmitting])

  const validate = () => {
    if (!state?.form?.files?.length) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsTouched(true)
    setIsSubmitting(true)
    validate()
  }

  return { handleSubmit, isValid, isTouched, validate }
}

export { useMustAttachLogic }
