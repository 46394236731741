import React from 'react'

import { Box, Paragraph } from '@allied-solutions/affinity'

import { useSiteMetadata } from '../hooks'
import { Layout } from './Layout'
import { NoRefIdNav } from './NoRefIdNav'

const FullScreenLayout = ({ children, nav = <NoRefIdNav /> }) => {
  const {
    alliedInfo: { address },
    copyrightText,
  } = useSiteMetadata()

  return (
    <Layout role="main">
      <Box display="flex" flexDirection="column">
        <Box
          display={[null, null, 'flex']}
          flexDirection="column"
          justifyContent="space-between"
          minHeight={[null, null, '100vh']}
        >
          {nav}
          <Box mt="22">{children}</Box>
          <Box px={[8, 8, 8, 15]} py={12} display={[null, null, 'flex']}>
            <Paragraph
              typeStyle="caption"
              textAlign="center"
              color="gray1000"
              mb={0}
            >
              {address}
            </Paragraph>
            <Paragraph
              typeStyle="caption"
              textAlign="center"
              color="gray1000"
              mb={0}
              ml="auto"
            >
              {copyrightText}
            </Paragraph>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export { FullScreenLayout }
