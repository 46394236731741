import React from 'react'

import { Paragraph } from '@allied-solutions/affinity'

import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import { Footer } from './Footer'

const SiteLayout = ({ children }) => {
  const { copyrightText } = useSiteMetadata()

  return (
    <>
      <main>{children}</main>

      {/* footer */}
      <Footer
        css={`
          @media print {
            display: none;
          }
        `}
      />
      <Paragraph
        mb={10}
        mt={20}
        typeStyle="caption"
        textAlign="center"
        color="disabled"
        css={`
          display: none;

          @media print {
            display: block;
          }
        `}
      >
        {copyrightText}
      </Paragraph>
    </>
  )
}

export { SiteLayout }
