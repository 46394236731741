import { debounce } from 'lodash'

import { useStore } from './useStore'

const useAddFormValuesToState = () => {
  const { dispatch, state } = useStore()

  const addFormValuesToState = async values => {
    return dispatch({
      type: 'addToState',
      payload: { form: { ...state.form, ...values } },
    })
  }

  return debounce(addFormValuesToState, 500, { leading: true, trailing: false })
}

export { useAddFormValuesToState }
