import { navigate } from 'gatsby'

import { useStore } from './useStore'

const fetch_retry = async (url, options, n) => {
  await sleep(100)
  try {
    return await fetch(url, options).then(res => {
      if (!res.ok) return Promise.reject(res)
      return res.json()
    })
  } catch (err) {
    if (n === 1) throw err
    return await fetch_retry(url, options, n - 1)
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function base64toBlob(base64Data, contentType) {
  contentType = contentType || ''
  var sliceSize = 1024
  var byteCharacters = atob(base64Data)
  var bytesLength = byteCharacters.length
  var slicesCount = Math.ceil(bytesLength / sliceSize)
  var byteArrays = new Array(slicesCount)

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize
    var end = Math.min(begin + sliceSize, bytesLength)

    var bytes = new Array(end - begin)
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

const useFinalSubmission = location => {
  const { state } = useStore()

  /**
   * get file args for final submission
   * @param {array} files array of file objects (name and base64 properties)
   * @param {object} values Formik values
   */
  const getFileArgs = async (files, values) => {
    return files
      ? Promise.all(
          files.map(async file => {
            let attachmentFileName

            const data = await uploadFileDataStream(file)
            attachmentFileName = data?.DocumentName

            const attachmentId = data?.DocumentId
            return {
              attachmentFileName,
              attachmentId,
              originalName: file.name,
            }
          })
        )
      : null
  }

  /**
   * upload file data stream
   * @param {object} file file object (name and base64 properties)
   */
  const uploadFileDataStream = async file => {
    const data = new FormData()

    const tempData = {
      ReferenceId: state.form.ReferenceID,
      IsLimc: state.form.MustAttachProofOfInsurance,
      IsMortgage: state.form.IsMortgage?? false,
      IsAgent: state.form.IsAgent ?? false,
      LenderCode: state.form.LenderCode ?? "",
      PolicyholderEmailAddress: state.form.PolicyholderEmailAddress ?? "",
    }
    Object.entries(tempData).map(([key, value]) => data.append(key, value))

    const fileBlob = base64toBlob(file.base64, file.type);
    //console.log('fileBlob', fileBlob)
    data.append('File', fileBlob, file.name)

    return fetch_retry(
      `${process.env.GATSBY_API_URL}/attachment`,
      {
        method: 'POST',
        body: data,
      }, 
      3
    )
  }

  /**
   * submit attachment to unitrac
   * @param {object} values Formik values
   * @param {string} fileName name of the file uploaded to BSS
   */
  const submitAttachment = async (values, fileName) => {
    return fetch_retry(
      `${process.env.GATSBY_API_URL}/SubmitAttachment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:
          '"' +
          JSON.stringify({
            ...values,
            AttachmentFileName: fileName,
          }).replace(/"/g, '\\"') +
          '"',
      },
      3
    )
  }

  /**
   * final InsuranceDocument submission
   * @param {object} values Formik values
   * @param {array} args array of objects with attachment name and ID
   */
  const submitInsuranceDocument = async (values, args) => {
    if (
      !values.ReferenceID ||
      values.MustAttachProofOfInsurance === 'true' ||
      values.MustAttachProofOfInsurance === true
    ) {
      //console.log('LIMC, skipping final submit')
      return
    }
    return fetch_retry(
      `${process.env.GATSBY_API_URL}/insurance-document`,
      {
        method: 'POST',
        headers: {
          'accept' : 'text/plain',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...values,
            AttachmentFiles: args
              ? args.map(arg => arg?.attachmentFileName)
              : [],
            AttachmentIDs: args ? args.map(arg => arg?.attachmentId) : [],
          }),
      },
      3
    )
  }

  const submitFinal = async (files, values) => {
    try {
      const args = await getFileArgs(files, values)

      await submitInsuranceDocument(values, args)
      navigate(location.pathname.replace('review', 'confirmation'))
    } catch (error) {
      console.error(error)

      if (error.status === 503) {
        navigate('/systems-down/')
      }
    }
  }

  return submitFinal
}

export { useFinalSubmission }
