import React from 'react'

import {
  Box,
  Paragraph,
  FormGroup,
  ScreenReaderText,
  H1,
  H2,
  Caption,
} from '@allied-solutions/affinity'

const ReviewLeftSide = ({location}) => (
  <Box>
    <Paragraph mb={6} typeStyle="labelLarge" textAlign="left" color="gray600">
      Submit
    </Paragraph>

    <H1 typeStyle="headlineMedium" fontWeight={900}>
      Review your information.
    </H1>

    <Paragraph typeStyle="bodyMedium" textAlign="left" color="gray600" mb={14}>
      Please ensure this information is correct before you submit. Click
      "Submit" and your job is done. We'll reach out with any further questions.
    </Paragraph>

    <H2
      className="h4"
      typeStyle="labelMedium"
      pt={7}
      mt={15}
      borderTop="1px solid"
      borderColor="muted"
    >
      Receive a confirmation
    </H2>
    <Paragraph typeStyle="bodySmall" textAlign="left" color="gray600">
      Enter your email address to receive a confirmation that we have received
      your information. {location.pathname != "/submit-insurance/mortgage/review/" && location.pathname !=  "/submit-insurance/other/review/" ? 
      "You should receive another email in 2-3 days when the verification process is complete (this is recommended)." :
      "" }
    </Paragraph>

    <FormGroup id="PolicyholderEmailAddress" mt={8} mb={8}>
      <FormGroup.Label
        for="review__input--email"
        typeStyle="labelMedium"
        color="gray1000"
        visible
        aria-label="Email Optional but recommended"
        hint={
          <>
            <ScreenReaderText>Recommended</ScreenReaderText>
            <Caption textAlign="right">Optional</Caption>
          </>
        }
      >
        Email Address
      </FormGroup.Label>
      <FormGroup.InputGroup>
        <FormGroup.InputGroup.Input
          type="email"
          id="review__input--email"
          aria-required="false"
        />
      </FormGroup.InputGroup>
    </FormGroup>
  </Box>
)

export { ReviewLeftSide }
